import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FadingModal from './FadingModal';
import { CloseIcon } from '../../assets/icons';
import useSubscribeToNewsletter from '../../libs/api/hooks/useSubscribeToNewsletter';
import { getIsValidEmail } from '../../libs/utils/getIsValidEmail';
import { FlexCol } from '../layout/FlexCol';
import { FlexRow } from '../layout/FlexRow';

interface JoinCommunityModalProps {
  opened?: boolean;
}

export const JoinCommunityModal = ({ opened }: JoinCommunityModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(opened ?? false);
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSaveSuccess = useCallback(() => {
    setIsSuccess(true);
    setEmail('');
    localStorage.setItem('subscribedToNews', 'true');
  }, []);

  const { mutate: subscribeToNewsletter, isLoading } = useSubscribeToNewsletter(onSaveSuccess);

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onPressSubscribe = useCallback(() => {
    if (getIsValidEmail(email)) {
      subscribeToNewsletter(email);
    } else {
      setIsError(true);
    }
  }, [email, subscribeToNewsletter]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        onPressSubscribe();
      }
    },
    [onPressSubscribe]
  );

  useEffect(() => {
    const alreadySubscribed = localStorage.getItem('subscribedToNews') === 'true';

    if (opened || alreadySubscribed) {
      return;
    }

    const popUpClosedTimestamp = localStorage.getItem('popUpClosedTimestamp');

    if (!popUpClosedTimestamp) {
      setTimeout(() => {
        setOpen(true);
      }, 30 * 1000);
      return;
    }

    const tenMinutes = 10 * 60 * 1000;

    if (!popUpClosedTimestamp || Date.now() - parseInt(popUpClosedTimestamp, 10) > tenMinutes) {
      setOpen(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    localStorage.setItem('popUpClosedTimestamp', Date.now().toString());
    setOpen(false);
  }, []);

  return (
    <FadingModal
      open={open}
      handleClose={handleClose}
      containerStyle={{
        width: isSmallScreen ? 'calc(100vw - 64px)' : undefined,
        padding: 0,
        maxWidth: 'sm'
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
      >
        <Alert severity='success'>Subscribed</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isError}
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      >
        <Alert severity='error'>Invalid email</Alert>
      </Snackbar>

      <FlexCol sx={{ backgroundColor: '#1437B7', width: '100%' }}>
        <FlexCol
          sx={{ background: 'radial-gradient(circle, #6682EA, transparent 85%)', width: '100%' }}
        >
          <FlexRow justifyContent='flex-end'>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: '#FFFFFF', padding: 8 }} />
            </IconButton>
          </FlexRow>

          <img
            src={require('../../assets/images/join-community-banner.png')}
            style={{ height: 'auto', width: '100%', zIndex: 1, marginBottom: 32, marginTop: 32 }}
            alt='https://youraitool.com/'
          />
        </FlexCol>
      </FlexCol>

      <FlexCol sx={{ padding: 8, alignItems: 'center' }}>
        <Typography
          fontSize={28}
          maxWidth='md'
          fontWeight={theme.typography.fontWeightBold}
          letterSpacing={-0.56}
          textAlign='center'
        >
          {t('join-community')}
        </Typography>

        <Typography
          fontSize={14}
          lineHeight={1.5}
          marginY={2}
          maxWidth='sm'
          letterSpacing={-0.28}
          textAlign='center'
        >
          {t('community-description')}
        </Typography>

        <FlexCol maxWidth='sm' sx={{ marginTop: 4, width: '100%' }}>
          <OutlinedInput
            sx={{
              borderRadius: '10px',
              height: '60px',
              width: '100%',
              borderColor: '#CBCBCB',
              backgroundColor: '#F9F9F9',
              fontWeight: theme.typography.fontWeightBold
            }}
            endAdornment={
              isSmallScreen ? null : isLoading ?
                <CircularProgress size={24} color='inherit' />
                :
                <InputAdornment position='end'>
                  <Button
                    disabled={!getIsValidEmail(email)}
                    variant='contained'
                    onClick={onPressSubscribe}
                    sx={{ backgroundColor: '#5271FF', textTransform: 'none' }}
                  >
                    Subscribe
                  </Button>
                </InputAdornment>

            }
            placeholder={t('enter-your-email-address') || ''}
            inputProps={{ style: { color: '#000000', width: '100%' } }}
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyPress}
          />

          {isSmallScreen ?
            isLoading ?
              <CircularProgress
                size={24}
                color='inherit'
                sx={{ alignSelf: 'center', marginTop: 4 }}
              />
              :
              <Button
                disabled={!getIsValidEmail(email)}
                variant='contained'
                onClick={onPressSubscribe}
                sx={{ backgroundColor: '#5271FF', textTransform: 'none', marginTop: 4 }}
              >
                Subscribe
              </Button>

            : null}
        </FlexCol>
      </FlexCol>
    </FadingModal>
  );
};
