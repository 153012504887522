import {
  Box,
  BoxProps,
  Button,
  FormControl,
  IconButton,
  InputAdornment, Menu,
  OutlinedInput, Radio, RadioGroup, Typography, useMediaQuery,
  useTheme
} from '@mui/material';
import React, { ChangeEvent, KeyboardEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterIcon, SearchIcon } from '../../assets/icons';
import { FlexRow } from '../layout/FlexRow';

interface SearchBoxProps<T = string> {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickSearch?: () => void;
  sx?: BoxProps['sx'];
  placeholder?: string;
  setFilter?: (key: T) => void;
  selectedFilter?: T;
  filters?: { name: string; value: T }[];
  height?: number;
  fontSize?: number;
}

const SearchBox = <T extends string>({
  value,
  onChange,
  onClickSearch,
  sx = {},
  placeholder,
  setFilter,
  selectedFilter,
  filters,
  height = 60,
  fontSize
}: SearchBoxProps<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenFilters = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseFilters = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && onClickSearch) {
        onClickSearch();
      }
    },
    [onClickSearch]
  );

  return (
    <FormControl fullWidth sx={sx}>
      <FlexRow gap={4}>
        <OutlinedInput
          sx={{
            borderRadius: '10px',
            height: height,
            flex: 1,
            borderColor: '#CBCBCB',
            backgroundColor: '#F9F9F9',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: fontSize ?? isSmallScreen ? 14: 20,
            paddingRight: 0
          }}
          id='outlined-adornment-amount'
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon style={{ width: 22, height: 22 }} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              <FlexRow sx={{ height: height }}>
                {value && onClickSearch &&
                  <Button onClick={onClickSearch} sx={{ color: theme.palette.primary.main }}>
                    {t('search')}
                  </Button>
                }

                {!!filters?.length && setFilter &&
                  <Box>
                    <IconButton
                      sx={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: 0,
                        height: '100%',
                        padding: 6,
                        borderLeft: 1,
                        borderColor: '#CBCBCB'
                      }}
                      onClick={handleOpenFilters}
                    >
                      <FilterIcon
                        style={{
                          height: 22,
                          width: 22,
                          fill: theme.palette.customColor.gray.dark,
                          color: theme.palette.customColor.gray.dark
                        }}
                      />
                    </IconButton>
                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseFilters}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      <RadioGroup
                        onChange={(event) => {
                          setFilter(event.target.value as T);
                          handleCloseFilters();
                        }}
                        value={selectedFilter}
                      >
                        {
                          filters.map((filter) =>
                            <FlexRow sx={{ alignItems: 'center', marginRight: 2 }} key={filter.value}>
                              <Radio value={filter.value} />
                              <Typography fontSize={14} fontWeight='500' color='gray'>
                                {t(filter.name)}
                              </Typography>
                            </FlexRow>
                          )
                        }
                      </RadioGroup>
                    </Menu>
                  </Box>
                }
              </FlexRow>
            </InputAdornment>
          }
          placeholder={placeholder}
          inputProps={{ style: { color: '#000000' } }}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyPress}
        />
      </FlexRow>
    </FormControl>
  );
};

export default SearchBox;
