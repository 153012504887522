import { Box, Button, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationTab } from './NavigationTab';
import { HeartIcon, RocketIcon } from '../../assets/icons';
import { useSetUserSearchQuery } from '../../libs/api/hooks/useSetUserSearchQuery';
import { useToggle } from '../../libs/hooks/utils/use-toggle';
import { getIsUserLoggedIn } from '../../libs/redux/slices/auth/authSelectors';
import {
  BASE_URL,
  getFavoritesPageLink,
  getSubmitToolPageLink,
  ROUTES
} from '../../libs/router/config/routes';
import SearchBox from '../data-display/SearchBox';
import { UserDrawerMenu } from '../data-display/UserDrawerMenu';
import { FlexCol } from '../layout/FlexCol';
import { FlexRow } from '../layout/FlexRow';
import GridContainer from '../layout/GridContainer';
import GridItem from '../layout/GridItem';
import LoginModal from '../modals/LoginModal';
import { LinkBox } from '../styled/LinkBox';

export const NAV_BAR_HEIGHT = 60;

export interface NavigationBarProps {
  searchBox?: 'never' | 'always' | 'onScroll';
}

export const NavigationBar = ({ searchBox }: NavigationBarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const [loginModalOpen, toggleLoginModalOpen] = useToggle(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchBox, setShowSearchBox] = useState(searchBox === 'always');

  const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { mutate: saveSearchQuery } = useSetUserSearchQuery();

  const setSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearch = useCallback(() => {
    navigate(`${ROUTES.SEARCH_PAGE}?q=${encodeURIComponent(searchValue)}`);
    saveSearchQuery(searchValue);
  }, [navigate, saveSearchQuery, searchValue]);

  const selectedTab = useMemo(() => {
    if (!location || !location.pathname) {
      return null;
    }

    if (
      location.pathname === ROUTES.LANDING_PAGE ||
      location.pathname.includes(ROUTES.TOOLS_PAGE) ||
      location.pathname.includes(ROUTES.SEARCH_PAGE)
    ) {
      return ROUTES.LANDING_PAGE;
    }

    if (location.pathname.includes(ROUTES.RECOMMENDED_TOOLS_PAGE)) {
      return ROUTES.RECOMMENDED_TOOLS_PAGE;
    }

    if (location.pathname.includes(ROUTES.CATEGORIES_PAGE)) {
      return ROUTES.CATEGORIES_PAGE;
    }

    if (location.pathname.includes(ROUTES.AI_NEWS_PAGE)) {
      return ROUTES.AI_NEWS_PAGE;
    }

    if (location.pathname.includes(ROUTES.FAQ_PAGE)) {
      return ROUTES.FAQ_PAGE;
    }
  }, [location]);

  useEffect(() => {
    if (searchBox === 'always') {
      setShowSearchBox(true);
      return;
    }

    if (searchBox === 'onScroll') {
      const handleScroll = () => {
        const threshold = 400;

        if (window.scrollY > threshold) {
          setShowSearchBox(true);
        } else {
          setShowSearchBox(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [searchBox]);

  if (isLgScreen) {
    return (
      <NavBarContainer>
        <GridContainer removeSpacers sx={{ height: '100%', paddingX: 4 }}>
          <GridItem xs={12} sx={{ minHeight: NAV_BAR_HEIGHT }}>
            <FlexRow sx={{ height: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <LinkBox to={BASE_URL} sx={{ marginRight: 6 }}>
                <img
                  src={require('../../assets/app/logo.png')}
                  style={{ height: '18px', marginLeft: 4, marginRight: 4 }}
                  title='best ai tools for any job'
                  alt='best ai tools'
                />
              </LinkBox>

              <UserDrawerMenu variant='small' />
            </FlexRow>
          </GridItem>
        </GridContainer>

        <LoginModal open={loginModalOpen} handleClose={toggleLoginModalOpen} />
      </NavBarContainer>
    );
  }

  return (
    <NavBarContainer>
      <FlexRow sx={{ height: NAV_BAR_HEIGHT, alignItems: 'center', paddingX: 4, gap: 2 }}>
        <LinkBox to={BASE_URL} sx={{ marginRight: 6 }}>
          <img
            src={require('../../assets/app/logo.png')}
            style={{ height: '18px', marginLeft: 4, marginRight: 4 }}
            title='best ai tools for any job'
            alt='best ai tools'
          />
        </LinkBox>

        <NavigationTab
          route={ROUTES.LANDING_PAGE}
          name={t('all-tools')}
          selected={selectedTab === ROUTES.LANDING_PAGE}
        />
        <NavigationTab
          route={ROUTES.RECOMMENDED_TOOLS_PAGE}
          name={t('recommended-tools')}
          selected={selectedTab === ROUTES.RECOMMENDED_TOOLS_PAGE}
        />
        <NavigationTab
          route={ROUTES.CATEGORIES_PAGE}
          name={t('categories')}
          selected={selectedTab === ROUTES.CATEGORIES_PAGE}
        />
        {/*<NavigationTab*/}
        {/*  route={ROUTES.AI_NEWS_PAGE}*/}
        {/*  name={t('ai-news')}*/}
        {/*  selected={selectedTab === ROUTES.AI_NEWS_PAGE}*/}
        {/*/>*/}
        <NavigationTab
          route={ROUTES.FAQ_PAGE}
          name={t('faq')}
          selected={selectedTab === ROUTES.FAQ_PAGE}
        />
        <NavigationTab route={ROUTES.BLOG_URL} name={t('blog')} selected={false} />

        <FlexCol
          sx={{
            flex: 1,
            marginX: 8,
            height: '100%',
            justifyContent: 'center',
            opacity: showSearchBox ? 1 : 0,
            visibility: showSearchBox ? 'visible' : 'hidden',
            pointerEvents: showSearchBox ? 'auto' : 'none',
            transition: 'opacity 0.3s ease-in-out'
          }}
          className={showSearchBox ? 'fade-in' : 'fade-out'}
        >
          <SearchBox
            value={searchValue}
            onChange={setSearch}
            onClickSearch={onClickSearch}
            placeholder={t('search-tool') || ''}
            height={40}
            fontSize={14}
          />
        </FlexCol>

        <NavigationButton
          variant='contained'
          startIcon={<RocketIcon />}
          href={getSubmitToolPageLink()}
          sx={{ border: 1, borderColor: theme.palette.customColor.border.main }}
        >
          {t('submit-your-tool')}
        </NavigationButton>

        {isUserLoggedIn ?
          <IconButton href={getFavoritesPageLink()} sx={{ marginLeft: 2 }}>
            <HeartIcon
              style={{
                color: theme.palette.customColor.gray.dark,
                fill: theme.palette.customColor.gray.dark,
                width: 22,
                height: 22
              }}
            />
          </IconButton>
          :
          <>
            <NavigationButton
              variant='contained'
              sx={{ border: 1, borderColor: theme.palette.customColor.border.main, marginLeft: 2 }}
              onClick={toggleLoginModalOpen}
            >
              {t('login')}
            </NavigationButton>
            <LoginModal open={loginModalOpen} handleClose={toggleLoginModalOpen} />
          </>
        }

        {isUserLoggedIn && <UserDrawerMenu variant='normal' />}
      </FlexRow>
    </NavBarContainer>
  );
};

const NavBarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  minHeight: NAV_BAR_HEIGHT,
  borderBottom: '1px solid',
  borderColor: theme.palette.customColor.border.light,
  position: 'sticky',
  top: 0,
  zIndex: 2
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  paddingX: 6,
  height: 40,
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  color: theme.palette.customColor.gray.dark,
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: '#0000000D',
    boxShadow: 'none'
  }
}));
