import React from 'react';
import { Helmet } from 'react-helmet';

import { BASE_PATH, ROUTES } from '../../../libs/router/config/routes';

const AiNewsPageMeta = () => {
  return (
    <Helmet>
      <title>The Latest Artificial Intelligence News from the Industry - YourAITool</title>
      <meta
        property='og:title'
        name='title'
        content='The Latest Artificial Intelligence News from the Industry - YourAITool'
      />
      <meta
        property='og:description'
        name='description'
        content='Stay up-to-date with the latest AI news from the industry! Discover trends and innovations shaping the AI landscaping to get valuable insights for your business.'
      />
      <link rel='canonical' href={`${BASE_PATH}/${ROUTES.AI_NEWS_PAGE}`} />
    </Helmet>

  );
};

export default AiNewsPageMeta;
