import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

import AiNewsPageMeta from './components/AiNewsPageMeta';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';

const AiNewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <AiNewsPageMeta />

      <GridContainer sx={{ paddingY: 12 }}>
        <GridItem xs={12}>
          <FlexCol sx={{ alignItems: 'center', height: '90vh' }}>
            <Typography
              maxWidth='sm'
              variant='h1'
              fontSize={24}
              fontWeight='400'
              letterSpacing='1px'
              textAlign='center'
              lineHeight={2}
            >
              AI news
            </Typography>

            <Typography
              maxWidth='md'
              fontSize={16}
              lineHeight={1.5}
              textAlign='center'
              variant='h2'
              marginTop={4}
              color='gray'
              fontWeight='400'
              zIndex={1}
              letterSpacing='1px'
              marginBottom={12}
            >
              This page is currently under construction
            </Typography>
          </FlexCol>
        </GridItem>
      </GridContainer>
    </PageContainer>
  );
};

export default AiNewsPage;
