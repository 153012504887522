import { ThemeOptions } from '@mui/material';

export const components: ThemeOptions['components'] = {
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: '60px',
        height: '60px'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: '60px',
        height: '60px'
      }
    }
  }
  // MuiTypography: {
  //   styleOverrides: {
  //     root: {
  //       whiteSpace: 'pre-line'
  //     }
  //   }
  // }
};
