import VerifiedIcon from '@mui/icons-material/Verified';
import { Button, CircularProgress, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CategoryTitle } from './components/CategoryTitle';
import FaqList from './components/FaqList';
import ToolCompanyData from './components/ToolCompanyData';
import ToolFeatures from './components/ToolFeatures';
import ToolHeader from './components/ToolHeader';
import ToolImage from './components/ToolImage';
import ToolPageMeta from './components/ToolPageMeta';
import ToolReviewsList from './components/ToolReviewsList';
import ToolTitle from './components/ToolTitle';
import ToolUseCases from './components/ToolUseCases';
import NotFound from '../../components/data-display/NotFound';
import YouTubeVideo from '../../components/data-display/YouTubeVideo';
import ContentContainer from '../../components/layout/ContentContainer';
import { FlexCol } from '../../components/layout/FlexCol';
import { FlexRow } from '../../components/layout/FlexRow';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import HorizontalToolList from '../../components/list/HorizontalToolList';
import { useGetRecommendedTools } from '../../libs/api/hooks/useGetRecommendedTools';
import { useGetTool } from '../../libs/api/hooks/useGetTool';
import { useGetToolFaq } from '../../libs/api/hooks/useGetToolFaq';
import { useGetToolFeatures } from '../../libs/api/hooks/useGetToolFeatures';
import { useGetToolOverview } from '../../libs/api/hooks/useGetToolOverview';
import { useGetToolReviews } from '../../libs/api/hooks/useGetToolReviews';
import { useGetToolsByCategory } from '../../libs/api/hooks/useGetToolsByCategory';
import { useGetToolUsecases } from '../../libs/api/hooks/useGetToolUsecases';
import { useGetToolVideos } from '../../libs/api/hooks/useGetToolVideos';
import { getToolPageLink, ROUTES } from '../../libs/router/config/routes';

const ToolPage = () => {
  const { categoryId, toolId } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();

  const { data: tool, isLoading: isLoadingTool, error } = useGetTool(toolId);

  const { data: reviews } = useGetToolReviews(toolId);

  const { data: features } = useGetToolFeatures(toolId);

  const { data: videos } = useGetToolVideos(toolId);

  const { data: faq } = useGetToolFaq(toolId);

  const { data: overview } = useGetToolOverview(toolId);

  const { data: usecases } = useGetToolUsecases(toolId);

  const { data: categoryTools, fetchNextPage: fetchNextCategoryTools } = useGetToolsByCategory(
    tool?.category,
    toolId
  );

  const { data: recommendedTools, fetchNextPage: fetchNextRecommendedTools } =
    useGetRecommendedTools();

  const onClickSeeMoreRecommended = useCallback(
    () => navigate(`${ROUTES.TOOLS_PAGE}/${tool?.category.id}`, { replace: true }),
    [tool, navigate]
  );

  useEffect(() => {
    if (toolId?.includes('_') && categoryId) {
      navigate(getToolPageLink(categoryId, toolId?.replace(/_/g, '-')), { replace: true });
    }
  }, [categoryId, toolId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categoryId, toolId]);

  if (isLoadingTool) {
    return (
      <PageContainer>
        <ContentContainer sx={{ paddingY: '32px' }}>
          <FlexCol sx={{ alignItems: 'center', height: '85vh', justifyContent: 'center' }}>
            <CircularProgress sx={{ marginY: '32px' }} />
          </FlexCol>
        </ContentContainer>
      </PageContainer>
    );
  }

  if (error || !tool || !toolId) {
    return (
      <PageContainer>
        <NotFound />
      </PageContainer>
    );
  }

  return (
    <PageContainer navigationBarProps={{ searchBox: 'always' }}>
      <ToolPageMeta
        toolName={tool.title}
        categoryName={tool.category_name}
        toolId={tool.id}
        categoryId={tool.category.id}
        toolImage={tool.image ?? ''}
      />

      <ToolHeader
        categoryId={tool.category.id}
        categoryName={tool.category_name}
        title={tool.title}
        toolId={tool.id}
        tool={tool}
        recommended={tool.recommended}
      />

      <Grid
        sx={{ paddingY: 8, justifyContent: 'center', padding: 4 }}
        container
        itemScope
        itemType='https://schema.org/ItemList'
      >
        <Grid container sx={{ justifyContent: 'center' }} xs={12}>
          <GridItem xs={12} sm={8} md={6}>
            <ToolTitle
              title={tool.title}
              verified={!!tool.company}
              categoryName={tool.category_name}
              categoryId={tool.category.id}
              rating={tool.rating}
            />
          </GridItem>
        </Grid>

        <Grid container sx={{ justifyContent: 'center', marginTop: 2 }} xs={12}>
          <GridItem xs={12} sm={8} md={6}>
            {tool.image &&
              <ToolImage
                image={tool.image}
                url={tool.website}
                title={tool.title}
                categoryName={tool.category_name}
              />
            }

            <Typography
              fontSize={28}
              letterSpacing={-0.56}
              lineHeight={1.5}
              textAlign='center'
              paddingY={10}
            >
              {tool.short_description}
            </Typography>
          </GridItem>
        </Grid>

        <Grid container sx={{ justifyContent: 'center', marginTop: 2 }} xs={12}>
          <GridItem
            xs={12}
            sm={10}
            md={8}
            sx={{ borderRadius: '10px', backgroundColor: '#F7F7F7', paddingY: 8 }}
          >
            <Grid container xs={12} columns={{ xs: 12, sm: 10, md: 8 }}>
              <GridItem xs={1} />

              <GridItem xs={10} sm={8} md={6} columns={{ xs: 10, sm: 8, md: 6 }}>
                <Grid container columns={{ xs: 10, sm: 8, md: 6 }} spacing={4}>
                  <GridItem xs={10} sm={3} md={2}>
                    <FlexCol>
                      <Typography
                        fontSize={28}
                        letterSpacing={-0.28}
                        lineHeight={1.5}
                        fontWeight={theme.typography.fontWeightBold}
                        whiteSpace='pre-line'
                        marginX={4}
                      >
                        {t('about-tool', { toolName: tool.title })}
                      </Typography>

                      {!!tool.price && <Typography
                        fontSize={16}
                        letterSpacing={-0.28}
                        lineHeight={1.5}
                        fontWeight={theme.typography.fontWeightBold}
                        whiteSpace='pre-line'
                        marginX={4}
                        marginY={2}
                      >
                        {`${tool.price}`}
                      </Typography>}
                    </FlexCol>
                  </GridItem>

                  <GridItem xs={10} sm={5} md={4}>
                    <Typography
                      fontSize={16}
                      letterSpacing={-0.28}
                      lineHeight={1.6}
                      color={theme.palette.customColor.gray.dark}
                      whiteSpace='pre-line'
                      marginX={4}
                    >
                      {tool.description}
                    </Typography>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem xs={1} />
            </Grid>
          </GridItem>
        </Grid>

        {!!videos?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 2 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4, marginY: 6 }}>
              <Grid container spacing={4}>
                {videos.map((video) =>
                  <GridItem xs={6} sm={6} md={4} key={video.id}>
                    <YouTubeVideo videoUrl={video.link} />
                  </GridItem>
                )}
              </Grid>
            </GridItem>
          </Grid>
        }

        {tool.company &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <Grid container spacing={4}>
                <GridItem xs={12} sm={12} lg={3}>
                  <CategoryTitle>{t('provider-details')}</CategoryTitle>

                  <FlexRow sx={{ alignItems: 'center', marginTop: 2 }} gap={2}>
                    <VerifiedIcon sx={{ color: '#42A5F5', width: 18, height: 18 }} />

                    <Typography
                      fontSize={14}
                      color={theme.palette.customColor.gray.dark}
                      sx={{ flexDirection: 'row', display: 'flex' }}
                    >
                      {t('verified-veridion')}
                    </Typography>
                  </FlexRow>
                </GridItem>

                <GridItem xs={12} sm={12} lg={9}>
                  <ToolCompanyData company={tool.company} />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        }

        {!!features?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <CategoryTitle>{t('features')}</CategoryTitle>

              <ToolFeatures features={features} />
            </GridItem>
          </Grid>
        }

        {!!usecases?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <Typography
                fontSize={50}
                fontWeight={theme.typography.fontWeightBold}
                lineHeight={1.2}
              >
                {t('best-usecases-for-tool', { toolName: tool.title })}
              </Typography>
              <ToolUseCases useCases={usecases} toolName={tool.title} />
            </GridItem>
          </Grid>
        }

        {!!reviews?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <CategoryTitle marginBottom={4}>{t('reviews')}</CategoryTitle>

              <ToolReviewsList reviews={reviews} toolId={tool.id}/>
            </GridItem>
          </Grid>
        }

        {!!overview?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <CategoryTitle sx={{ marginBottom: 4 }}>{t('overview')}</CategoryTitle>
              {overview.map((overviewItem, index) =>
                <Grid container columns={12} key={overviewItem.id}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row', marginY: 2 }}
                    spacing={4}
                  >
                    <GridItem xs={3} sm={2} md={2} lg={2}>
                      <Typography fontSize={14} fontWeight='600' color='gray' lineHeight={1.5}>
                        {overviewItem.name}
                      </Typography>
                    </GridItem>

                    <GridItem xs={9} sm={10} md={10} lg={10}>
                      {overviewItem?.specs?.map((spec) =>
                        <Typography
                          fontSize={14}
                          fontWeight='400'
                          key={spec}
                          color='gray'
                          marginLeft={4}
                          lineHeight={1.5}
                        >
                          {spec}
                        </Typography>
                      )}
                    </GridItem>
                  </Grid>

                  {index !== overview?.length - 1 &&
                    <Divider flexItem orientation='horizontal' sx={{ width: '100%' }} />
                  }
                </Grid>
              )}
            </GridItem>
          </Grid>
        }

        {!!faq?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={12} sm={8} md={6} sx={{ padding: 4 }}>
              <CategoryTitle marginBottom={4}>
                {`Everything you need to know about ${tool.title}`}
              </CategoryTitle>

              <FaqList
                questions={faq}
                hideDivider
                setSx={(expanded) => ({
                  padding: 4,
                  border: 1,
                  borderColor: expanded
                    ? theme.palette.primary.main
                    : theme.palette.customColor.border.light,
                  marginBottom: 2
                })}
              />
            </GridItem>
          </Grid>
        }

        {!!categoryTools?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={10}>
              <FlexRow
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 4
                }}
              >
                <CategoryTitle sx={{ marginY: 0 }}>{t('alternatives')}</CategoryTitle>

                <Button
                  variant='text'
                  sx={{
                    fontSize: 14,
                    color: '#000000',
                    paddingX: 4,
                    borderRadius: '16px'
                  }}
                  onClick={onClickSeeMoreRecommended}
                >
                  {t('see-more')}
                </Button>
              </FlexRow>

              <HorizontalToolList onMiddleReached={fetchNextCategoryTools} tools={categoryTools} />
            </GridItem>
          </Grid>
        }

        {!!categoryTools?.length &&
          <Grid container sx={{ justifyContent: 'center', marginTop: 6 }} xs={12}>
            <GridItem xs={10}>
              <CategoryTitle marginBottom={4}>{t('you-might-like')}</CategoryTitle>
              <HorizontalToolList
                onMiddleReached={fetchNextRecommendedTools}
                tools={recommendedTools}
              />
            </GridItem>
          </Grid>
        }
      </Grid>
    </PageContainer>
  );
};

export default ToolPage;
