import { Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToolList from '../../components/data-display/ToolList';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import { getFavoriteTools } from '../../libs/redux/slices/tools/toolsSelectors';

const FavoritesPage = () => {
  const { t } = useTranslation();

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(
    () => favoriteTools.map((favoriteTool) => favoriteTool.id),
    [favoriteTools]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <GridContainer sx={{ paddingY: 12 }}>
        <GridItem xs={12}>
          <FlexCol sx={{ alignItems: 'center', marginBottom: 12 }}>
            <Typography
              maxWidth='sm'
              variant='h1'
              fontSize={24}
              fontWeight='400'
              letterSpacing='1px'
              textAlign='center'
              lineHeight={2}
            >
              {t('favorites')}
            </Typography>
          </FlexCol>

          <ToolList tools={favoriteTools} favoriteToolsIds={favoriteToolsIds} />
        </GridItem>
      </GridContainer>
    </PageContainer>
  );
};

export default FavoritesPage;
