// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCcfh7RLIiTBWLh9bdUvT_BF0c4ahnXf8s',
  authDomain: 'youraitool.firebaseapp.com',
  projectId: 'youraitool',
  storageBucket: 'youraitool.appspot.com',
  messagingSenderId: '582716854023',
  appId: '1:582716854023:web:f8d84cb8c72d019afeffa2',
  measurementId: 'G-VKHH88X1RH'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver
});

export const db = getFirestore(app);
