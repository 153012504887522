import { BoxProps, styled } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import * as React from 'react';
import { MouseEventHandler } from 'react';


interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>((props, ref) => {
  const { children, in: open, onClick, onEnter, onExited, ...other } = props;

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as unknown as HTMLElement, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as unknown as HTMLElement, true);
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.displayName = 'Fade';

interface FadingModalProps {
  open: boolean;
  handleClose: () => void;
  containerStyle?: BoxProps['sx'];
  children: React.ReactNode;
}

const FadingModal = ({ open, handleClose, containerStyle = {}, children }: FadingModalProps) => {
  return (
    <Modal
      aria-labelledby='spring-modal-title'
      aria-describedby='spring-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade
        }
      }}
    >
      <Fade in={open}>
        <ChildrenContainer sx={containerStyle}>{children}</ChildrenContainer>
      </Fade>
    </Modal>
  );
};

export default FadingModal;

const ChildrenContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.25);
  padding: 16px;
  overflow: hidden;
`;
