import { useQuery } from '@tanstack/react-query';
import {
  query,
  collection, getDocs
} from 'firebase/firestore';

import { db } from '../config/firebase';

interface IVideo {
  id: string;
  name: string;
  link: string;
}

const getToolOverview = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const videosQuery = query(
    collection(db, 'tool', toolId, 'videos'),
  );

  const videosDocs = await getDocs(videosQuery);

  if (videosDocs?.docs) {
    return videosDocs.docs.map(doc => ({ ...(doc.data() as IVideo), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetToolVideos = (toolId?: string) =>
  useQuery({
    queryKey: ['toolVideos', toolId],
    queryFn: () => getToolOverview(toolId),
    enabled: !!toolId
  });
