import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseIcon, ExternalLinkIcon, StarIcon } from '../../../assets/icons';
import FavoriteButton from '../../../components/buttons/FavoriteButton';
import { FlexCol } from '../../../components/layout/FlexCol';
import { FlexRow } from '../../../components/layout/FlexRow';
import { NAV_BAR_HEIGHT } from '../../../components/navigation/NavigationBar';
import { ITool } from '../../../libs/api/hooks/useGetTool';
import { useActionDispatch } from '../../../libs/redux/root/store';
import {
  addToolToFavorites,
  removeToolFromFavorites
} from '../../../libs/redux/slices/tools/toolsActions';
import { getFavoriteTools } from '../../../libs/redux/slices/tools/toolsSelectors';
import { getLandingPageLink, getToolCategoryPageLink } from '../../../libs/router/config/routes';

interface ToolHeaderProps {
  categoryId: string;
  categoryName: string;
  title: string;
  toolId: string;
  tool: ITool;
  recommended?: boolean;
}

const ToolHeader = ({
  categoryId,
  categoryName,
  title,
  toolId,
  tool,
  recommended
}: ToolHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useActionDispatch();

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(
    () => favoriteTools.map((favoriteTool) => favoriteTool.id),
    [favoriteTools]
  );

  const [isFavorite, setIsFavorite] = useState(favoriteToolsIds.includes(toolId as string));

  const onClickFavorite = useCallback(() => {
    if (!toolId || !tool) {
      return;
    }

    if (isFavorite) {
      setIsFavorite(false);
      dispatch(removeToolFromFavorites(toolId));
    } else {
      setIsFavorite(true);
      dispatch(addToolToFavorites(tool));
    }
  }, [dispatch, isFavorite, setIsFavorite, tool, toolId]);

  useEffect(
    () => setIsFavorite(favoriteToolsIds.includes(toolId as string)),
    [favoriteToolsIds, toolId]
  );

  return (
    <FlexCol
      sx={{
        paddingY: 1,
        backgroundColor: recommended ? theme.palette.primary.main : '#000000',
        position: 'sticky',
        top: NAV_BAR_HEIGHT,
        zIndex: 1
      }}
    >
      <FlexCol sx={{ position: 'absolute', top: NAV_BAR_HEIGHT + 24, right: '8.33%', gap: 4 }}>
        <FlexCol gap={2} alignItems='center'>
          <FavoriteButton
            isFavorite={isFavorite}
            size={22}
            onClick={onClickFavorite}
            sx={{
              width: 60,
              height: 60,
              border: 1,
              borderColor: theme.palette.customColor.border.light
            }}
          />

          <Typography color={theme.palette.customColor.gray.light} fontSize={16}>
            {t('favorite')}
          </Typography>
        </FlexCol>

        {tool.website &&
          <FlexCol gap={2} alignItems='center'>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <ActionButton href={tool.website} target='_blank'>
              <ExternalLinkIcon style={{ width: 22, height: 22, color: '#000000' }} />
            </ActionButton>

            <Typography color={theme.palette.customColor.gray.light} fontSize={16}>
              {t('visit')}
            </Typography>
          </FlexCol>
        }
      </FlexCol>

      <Grid container columns={12} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} md={10}>
          <FlexRow sx={{ justifyContent: 'space-between', height: '100%', alignItems: 'center' }}>
            <Breadcrumbs
              sx={{
                color: '#FFFFFF',
                flex: 1,
                paddingLeft: 4,
                display: { xs: recommended ? 'none' : 'block', sm: 'block' }
              }}
              aria-label='breadcrumb'
              separator={<NavigateNextIcon fontSize='small' />}
            >
              <Link
                underline='hover'
                color='#FFFFFF'
                href={getLandingPageLink()}
                fontSize={14}
                fontWeight='400'
              >
                Tools
              </Link>

              <Link
                underline='hover'
                color='#FFFFFF'
                href={getToolCategoryPageLink(categoryId, true)}
                fontSize={14}
                fontWeight='400'
                rel='canonical'
              >
                {categoryName}
              </Link>

              <Typography fontSize={14} fontWeight='600' color='#FFFFFF'>
                {title}
              </Typography>
            </Breadcrumbs>

            {recommended &&
              <FlexRow sx={{ alignItems: 'center', flex: 1, justifyContent: 'center' }} gap={2}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    backgroundColor: '#FFFFFF',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <StarIcon
                    style={{
                      color: theme.palette.primary.main,
                      fill: theme.palette.primary.main,
                      width: 14,
                      height: 14
                    }}
                  />
                </Box>

                <Typography fontSize={14} fontWeight='600' color='#FFFFFF'>
                  {t('recommended-tool')}
                </Typography>
              </FlexRow>
            }

            <FlexRow sx={{ alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
              <IconButton onClick={() => navigate(-1)}>
                <CloseIcon style={{ color: '#FFFFFF' }} />
              </IconButton>
            </FlexRow>
          </FlexRow>
        </Grid>
      </Grid>

      <FlexRow>
        <Box
          sx={{
            width: 25,
            height: 25,
            position: 'absolute',
            bottom: -25,
            left: 0,
            backgroundColor: recommended ? theme.palette.primary.main : '#000000'
          }}
        >
          <Box
            sx={{ borderTopLeftRadius: 20, width: 25, height: 25, backgroundColor: '#FFFFFF' }}
          />
        </Box>

        <Box
          sx={{
            width: 25,
            height: 25,
            position: 'absolute',
            bottom: -25,
            right: 0,
            backgroundColor: recommended ? theme.palette.primary.main : '#000000'
          }}
        >
          <Box
            sx={{ borderTopRightRadius: 20, width: 25, height: 25, backgroundColor: '#FFFFFF' }}
          />
        </Box>
      </FlexRow>
    </FlexCol>
  );
};

export default ToolHeader;

const ActionButton = styled(Button)(({ theme }) => ({
  width: 60,
  height: 60,
  border: '1px solid',
  borderColor: theme.palette.customColor.border.light,
  backgroundColor: '#FFFFFF',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: '50%',
  transition: 'box-shadow 0.2s ease-in-out',
  '&.MuiIconButton-root:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
  }
}));
