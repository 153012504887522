import { useInfiniteQuery } from '@tanstack/react-query';
import { collection, getDocs, limit, query, startAfter } from 'firebase/firestore';

import { ITool, TOOL_STATUS } from './useGetTool';
import { useGetToolsCount } from './useGetToolsCount';
import { db } from '../config/firebase';
import { getToolsFilterBy, TOOLS_FILTER_KEYS } from '../helpers/getToolsFilterBy';
import { getToolsSortBy, SORT_BY_KEYS } from '../helpers/getToolsSortBy';

interface UseGetToolsOptions {
  sortBy?: SORT_BY_KEYS;
  filterBy?: TOOLS_FILTER_KEYS;
}

const PAGE_LIMIT = 24;

const getTools = async (lastDocSnapshot = null, options?: UseGetToolsOptions) => {
  let toolsQuery;

  const orderToolsBy =
    options?.filterBy && options.filterBy !== TOOLS_FILTER_KEYS.ALL
      ? []
      : getToolsSortBy(options?.sortBy);

  const filterToolsBy = getToolsFilterBy(options?.filterBy);

  const toolCollection = collection(db, 'tool');

  if (lastDocSnapshot) {
    toolsQuery = query(
      toolCollection,
      ...orderToolsBy,
      ...filterToolsBy,
      startAfter(lastDocSnapshot),
      limit(PAGE_LIMIT)
    );
  } else {
    toolsQuery = query(toolCollection, ...orderToolsBy, ...filterToolsBy, limit(PAGE_LIMIT));
  }

  const toolSnapshot = await getDocs(toolsQuery);

  const tools =
    toolSnapshot.docs
      .map((doc) => ({ ...(doc.data() as ITool), id: doc.id }))
      ?.filter(
        (tool) =>
          tool.category.id?.length > 0 &&
          tool.status.id !== TOOL_STATUS.UNPUBLISHED &&
          tool.status.id !== TOOL_STATUS.SCREENSHOT_ERROR
      ) ?? [];

  return {
    tools: tools,
    nextPage:
      toolSnapshot.docs.length % PAGE_LIMIT === 0
        ? toolSnapshot.docs[toolSnapshot.docs.length - 1]
        : null
  };
};

export const useGetTools = (options?: UseGetToolsOptions) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['tools', options],
    queryFn: ({ pageParam = null }) => getTools(pageParam, options),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true
  });

  const { data: count } = useGetToolsCount(options?.filterBy);

  return {
    data: data?.pages.flatMap((page) => page.tools) ?? [],
    count: count,
    ...rest
  };
};
