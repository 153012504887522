import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import NotFound from '../../components/data-display/NotFound';
import PageContainer from '../../components/layout/PageContainer';
import { useGetTool } from '../../libs/api/hooks/useGetTool';
import { ROUTES } from '../../libs/router/config/routes';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const toolId = useMemo(() => {
    const parts = location.pathname.split('/');

    return parts[parts.length - 1];
  }, [location.pathname]);

  const { data: tool } = useGetTool(toolId);

  useEffect(() => {
    if (tool) {
      navigate(`${ROUTES.TOOLS_PAGE}/${tool.category.id}/${tool.id}`);
    }
  }, [navigate, tool]);

  return (
    <PageContainer>
      <NotFound/>
    </PageContainer>
  );
};

export default NotFoundPage;
