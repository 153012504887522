import { useQuery } from '@tanstack/react-query';
import {
  query,
  collection, getDocs
} from 'firebase/firestore';

import { db } from '../config/firebase';

export interface IFeature {
  id: string;
  name: string;
  description: string;
}

const getToolFeatures = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const reviewsQuery = query(
    collection(db, 'tool', toolId, 'features'),
  );

  const reviewDocs = await getDocs(reviewsQuery);

  if (reviewDocs?.docs) {
    return reviewDocs.docs.map(doc => ({ ...(doc.data() as IFeature), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetToolFeatures = (toolId?: string) =>
  useQuery({
    queryKey: ['toolFeatures', toolId],
    queryFn: () => getToolFeatures(toolId),
    enabled: !!toolId
  });
