import { useQuery } from '@tanstack/react-query';
import {
  query,
  collection, getDocs
} from 'firebase/firestore';

import { db } from '../config/firebase';

export interface IFaqItem {
  id: string;
  question: string;
  answer: string;
}

const getToolFaq = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const faqQuery = query(
    collection(db, 'tool', toolId, 'faq'),
  );

  const faqDocs = await getDocs(faqQuery);

  if (faqDocs?.docs) {
    return faqDocs.docs.map(doc => ({ ...(doc.data() as IFaqItem), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetToolFaq = (toolId?: string) =>
  useQuery({
    queryKey: ['toolFaq', toolId],
    queryFn: () => getToolFaq(toolId),
    enabled: !!toolId
  });
