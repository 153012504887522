import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';

import { ICategory } from './useGetCategoryById';
import { db } from '../config/firebase';

const getCategories = async () => {
  const categoriesQuery = query(collection(db, 'categories'), orderBy('tools', 'desc'));
  const categoriesSnapshot = await getDocs(categoriesQuery);

  return categoriesSnapshot.docs.map((doc) => ({
    ...(doc.data() as ICategory),
    id: doc.id
  }));
};

export const useGetCategories = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['categories'],
    queryFn: getCategories
  });

  return {
    data: data ?? [],
    ...rest
  };
};
