import { IconButton } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  DiscordIcon,
  YoutubeIcon
} from '../../assets/icons';
import { FlexRow } from '../layout/FlexRow';
const SOCIAL_NETWORKS = {
  TWITTER: {
    ICON: TwitterIcon,
    IDENTIFIER: 'twitter'
  },
  DISCORD: {
    ICON: DiscordIcon,
    IDENTIFIER: 'discord'
  },
  LINKEDIN: {
    ICON: LinkedinIcon,
    IDENTIFIER: 'linkedin'
  },
  FACEBOOK: {
    ICON: FacebookIcon,
    IDENTIFIER: 'facebook'
  },
  INSTAGRAM: {
    ICON: InstagramIcon,
    IDENTIFIER: 'instagram'
  },
  YOUTUBE: {
    ICON: YoutubeIcon,
    IDENTIFIER: 'youtube'
  }
};

interface SocialButtonsProps {
  socialLinks: string[];
}

const SocialButton = ({ icon: Icon, url }: { icon: React.ElementType; url: string }) => {
  const onClick = useCallback(() => {
    window.open(url, '_blank');
  }, [url]);

  return (
    <FlexRow sx={{ alignItems: 'center' }}>
      <IconButton onClick={onClick}>
        <Icon style={{ height: '20px', width: '20px', margin: 5 }} alt={url} />
      </IconButton>
    </FlexRow>
  );
};

const SocialButtons = ({ socialLinks }: SocialButtonsProps) => {
  const socialButtons = useMemo(() => {
    const buttons: React.ReactElement[] = [];

    socialLinks?.map((link) => {
      switch (true) {
      case link.includes(SOCIAL_NETWORKS.TWITTER.IDENTIFIER):
        buttons.push(<SocialButton icon={SOCIAL_NETWORKS.TWITTER.ICON} url={link} key={link} />);
        break;
      case link.includes(SOCIAL_NETWORKS.DISCORD.IDENTIFIER):
        buttons.push(<SocialButton icon={SOCIAL_NETWORKS.DISCORD.ICON} url={link} key={link} />);
        break;
      case link.includes(SOCIAL_NETWORKS.LINKEDIN.IDENTIFIER):
        buttons.push(<SocialButton icon={SOCIAL_NETWORKS.LINKEDIN.ICON} url={link} key={link} />);
        break;
      case link.includes(SOCIAL_NETWORKS.FACEBOOK.IDENTIFIER):
        buttons.push(<SocialButton icon={SOCIAL_NETWORKS.FACEBOOK.ICON} url={link} key={link} />);
        break;
      case link.includes(SOCIAL_NETWORKS.INSTAGRAM.IDENTIFIER):
        buttons.push(
          <SocialButton icon={SOCIAL_NETWORKS.INSTAGRAM.ICON} url={link} key={link} />
        );
        break;
      case link.includes(SOCIAL_NETWORKS.YOUTUBE.IDENTIFIER):
        buttons.push(<SocialButton icon={SOCIAL_NETWORKS.YOUTUBE.ICON} url={link} key={link} />);
        break;
      default:
        break;
      }
    }, []);

    return buttons;
  }, [socialLinks]);

  if (!socialButtons.length) {
    return null;
  }

  return <FlexRow sx={{ height: '100%', marginLeft: '-10px', marginRight: '-10px' }}>{socialButtons}</FlexRow>;
};

export default SocialButtons;
