import { ThemeOptions } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  primary: {
    main: '#3F5FD6'
  },
  customColor: {
    yellow: {
      main: '#FCD34D'
    },
    gray: {
      main: '#888888',
      dark: '#666666',
      light: '#999999'
    },
    mediumGray: {
      main: '#BBBBBB'
    },
    concrete: {
      main: '#64748B'
    },
    border: {
      main: '#0000001A',
      light: '#E5E5E5'
    },
    red: {
      main: '#FF4E4E'
    }
  }
} as ThemeOptions['palette'];
