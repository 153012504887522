import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './languages/en';

use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for React
    }
  })
  .then();
