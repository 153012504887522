import { useMutation } from '@tanstack/react-query';
import { collection, addDoc } from 'firebase/firestore';

import { db } from '../config/firebase';

export interface ISubmittedTool {
  email: string;
  toolName: string;
  website: string;
  shortDescription: string;
  completeDescription: string;
  categories: string[];
  price: string;
  features: string[];
  startingPrice: number;
  owner: boolean;
}

export const useSetSubmitTool = (onSuccess?: () => void) => {
  const saveNewToolData = async (toolData: ISubmittedTool) => {
    await addDoc(collection(db, 'submitted_tool'), toolData);
  };

  return useMutation(saveNewToolData, {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    }
  });
};
