import {
  Alert,
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput, Snackbar,
  Typography,
  useTheme
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import SocialButtons from './SocialButtons';
import backgroundFooterImage from '../../assets/images/footer-background.png';
import useSubscribeToNewsletter from '../../libs/api/hooks/useSubscribeToNewsletter';
import { useToggle } from '../../libs/hooks/utils/use-toggle';
import {
  BASE_URL,
  getCategoriesPageLink,
  getFaqPageLink, getLandingPageLink,
  getRecommendedToolsPageLink,
  ROUTES
} from '../../libs/router/config/routes';
import { getIsValidEmail } from '../../libs/utils/getIsValidEmail';
import { FlexCol } from '../layout/FlexCol';
import { FlexRow } from '../layout/FlexRow';
import GridContainer from '../layout/GridContainer';
import GridItem from '../layout/GridItem';
import { JoinCommunityModal } from '../modals/JoinCommunityModal';
import { LinkBox } from '../styled/LinkBox';

const DEFAULT_SOCIAL_LINKS = [
  'https://twitter.com/YourAITool',
  'https://discord.com/invite/XHFt4sJRnw',
  'https://www.linkedin.com/company/your-ai-tool/',
  'https://www.instagram.com/youraitool/'
];

const FooterLink = ({
  title,
  link,
  onClick
}: {
  title: string;
  link?: string;
  onClick?: () => void;
}) =>
  <Typography
    onClick={onClick ?? undefined}
    fontSize={14}
    fontWeight='600'
    component='a'
    href={link}
    target='_blank'
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline'
      },
      cursor: 'pointer'
    }}
  >
    {title}
  </Typography>;
const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [subscribeModalOpened, toggleSubscribeModal] = useToggle(false);

  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSaveSuccess = useCallback(() => {
    setIsSuccess(true);
    setEmail('');
  }, []);

  const { mutate: subscribeToNewsletter, isLoading } = useSubscribeToNewsletter(onSaveSuccess);

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onPressSubscribe = useCallback(() => {
    if (getIsValidEmail(email)) {
      subscribeToNewsletter(email);
    } else {
      setIsError(true);
    }
  }, [email, subscribeToNewsletter]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        onPressSubscribe();
      }
    },
    [onPressSubscribe]
  );

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [isSuccess]);

  return (
    <GridContainer>
      <GridItem xs={12} sx={{ marginY: 12 }}>
        <FlexCol
          sx={{
            background: `transparent url(${backgroundFooterImage}) 0% 0% no-repeat padding-box`,
            backgroundSize: 'cover',
            height: 300,
            width: '100%',
            justifyContent: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
            border: 1
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isSuccess}
            autoHideDuration={3000}
            onClose={() => setIsSuccess(false)}
          >
            <Alert severity='success'>Subscribed</Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isError}
            autoHideDuration={3000}
            onClose={() => setIsError(false)}
          >
            <Alert severity='error'>Invalid email</Alert>
          </Snackbar>

          <GridContainer>
            <GridItem xs={12}>
              <Typography
                fontSize={28}
                maxWidth='md'
                color='white'
                fontWeight={theme.typography.fontWeightBold}
                letterSpacing={-0.56}
              >
                {t('join-community')}
              </Typography>

              <Typography
                fontSize={14}
                lineHeight={1.5}
                maxWidth='sm'
                color='white'
                marginY={2}
                letterSpacing={-0.28}
              >
                {t('community-description')}
              </Typography>

              <FlexCol maxWidth='sm' sx={{ marginTop: 4 }}>
                <OutlinedInput
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    height: '60px',
                    width: '100%'
                  }}
                  endAdornment={
                    email ?
                      isLoading ?
                        <CircularProgress size={24} color='inherit' />
                        :
                        <InputAdornment position='end'>
                          <Button
                            variant='contained'
                            onClick={onPressSubscribe}
                            sx={{ backgroundColor: '#5271FF' }}
                          >
                            Subscribe
                          </Button>
                        </InputAdornment>

                      : null
                  }
                  placeholder='Enter your email'
                  inputProps={{ style: { color: '#000000', width: '100%' } }}
                  value={email}
                  onChange={handleEmailChange}
                  onKeyDown={handleKeyPress}
                />
              </FlexCol>
            </GridItem>
          </GridContainer>
        </FlexCol>
      </GridItem>

      <GridContainer removeSpacers sx={{ paddingBottom: 4 }} contentSpacing={4}>
        <GridItem md={6}>
          <FlexCol gap={4}>
            <LinkBox to={BASE_URL}>
              <img
                src={require('../../assets/app/logo.png')}
                style={{ height: 18, zIndex: 1 }}
                alt='https://youraitool.com/'
              />
            </LinkBox>

            <SocialButtons socialLinks={DEFAULT_SOCIAL_LINKS} />

            <Typography fontSize={20} fontWeight={theme.typography.fontWeightBold} maxWidth='sm'>
              {t('what-is-yat')}
            </Typography>

            <Typography fontSize={16} color={theme.palette.customColor.gray.main} maxWidth='sm'>
              {t('footer-description')}
            </Typography>
          </FlexCol>
        </GridItem>

        <GridItem md={6}>
          <FlexRow gap={8} sx={{ justifyContent: 'flex-end' }}>
            <FlexCol gap={2}>
              <Typography
                fontSize={14}
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.customColor.gray.dark}
              >
                {t('resources').toUpperCase()}
              </Typography>

              <FooterLink title={t('all-tools')} link={getLandingPageLink()} />

              <FooterLink title={t('recommended-tools')} link={getRecommendedToolsPageLink()} />

              <FooterLink title={t('categories')} link={getCategoriesPageLink()} />

              {/*<FooterLink title={t('ai-news')} link='https://youraitool.com/aiNews'/>*/}

              <FooterLink title={t('faq')} link={getFaqPageLink()} />

              <FooterLink title={t('blog')} link={ROUTES.BLOG_URL} />
            </FlexCol>

            <FlexCol gap={2}>
              <Typography
                fontSize={14}
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.customColor.gray.dark}
              >
                {t('follow-us').toUpperCase()}
              </Typography>

              <FooterLink title='Twitter' link='https://twitter.com/YourAITool' />

              <FooterLink title='Discord' link='https://discord.com/invite/XHFt4sJRnw' />

              <FooterLink title='Instagram' link='https://www.instagram.com/youraitool/' />

              <FooterLink title='Linkedin' link='https://www.linkedin.com/company/your-ai-tool/' />
            </FlexCol>

            <FlexCol gap={2}>
              <Typography
                fontSize={14}
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.customColor.gray.dark}
              >
                {t('useful-links').toUpperCase()}
              </Typography>

              <FooterLink title={t('subscribe-to-news')} onClick={toggleSubscribeModal} />
              {subscribeModalOpened && <JoinCommunityModal opened />}
            </FlexCol>
          </FlexRow>
        </GridItem>

        <GridItem xs={12}>
          <Typography fontSize={14} lineHeight={1.5} marginTop={8} color='gray'>
            {`© ${new Date().getFullYear()} YourAITool. ${t('all-rights-reserved')}`}
          </Typography>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

export default Footer;
