import { useInfiniteQuery } from '@tanstack/react-query';
import { collection, getDocs, query, orderBy, startAfter, limit } from 'firebase/firestore';

import { ITool } from './useGetTool';
import { db } from '../config/firebase';

const PAGE_LIMIT = 21;

const getTools = async (lastDocSnapshot = null) => {
  let toolsQuery;

  if (lastDocSnapshot) {
    toolsQuery = query(
      collection(db, 'tool'),
      orderBy('title', 'desc'),
      startAfter(lastDocSnapshot),
      limit(PAGE_LIMIT)
    );
  } else {
    toolsQuery = query(collection(db, 'tool'), orderBy('title', 'desc'), limit(PAGE_LIMIT));
  }

  const toolSnapshot = await getDocs(toolsQuery);

  return {
    tools: toolSnapshot.docs.map((doc) => ({ ...(doc.data() as ITool), id: doc.id })),
    nextPage: toolSnapshot.docs.length ? toolSnapshot.docs[toolSnapshot.docs.length - 1] : null
  };
};

export const useGetRecommendedTools = () => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['recommendedTools'],
    queryFn: ({ pageParam = null }) => getTools(pageParam),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true
  });

  return {
    data: data?.pages.flatMap((page) => page.tools) ?? [],
    ...rest
  };
};
