import { orderBy } from 'firebase/firestore';

export enum SORT_BY_KEYS {
  RECOMMENDED = 'recommended',
  TITLE = 'title',
  RATING = 'rating'
}

export enum SORT_BY_DIRECTION {
  ASC = 'asc',
  DESC = 'desc'
}

export const getToolsSortBy = (
  sortBy?: SORT_BY_KEYS,
  direction?: SORT_BY_DIRECTION
) => {
  if (!sortBy) {
    return [
      orderBy(SORT_BY_KEYS.RECOMMENDED, SORT_BY_DIRECTION.DESC),
      orderBy(SORT_BY_KEYS.TITLE, SORT_BY_DIRECTION.ASC)
    ];
  }

  return [orderBy(sortBy, direction ?? SORT_BY_DIRECTION.ASC)];
};
