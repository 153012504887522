import { Button, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronRightIcon } from '../../assets/icons';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import { useGetFaq } from '../../libs/api/hooks/useGetFaq';
import FaqList from '../tool-page/components/FaqList';

const FaqPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const { data, isLoading } = useGetFaq();

  const handleEmailClick = useCallback(() => {
    const mailtoUrl = `mailto:${'hello@youraitool.com'}?subject=${encodeURIComponent(
      'Hello, Your Ai Tool Team!'
    )}`;
    window.location.href = mailtoUrl;
  }, []);

  const selectedQuestions = useMemo(() => {
    if (!selectedCategory || !data) {
      return [];
    }

    const questions = data.find((faq) => faq.id === selectedCategory)?.questions ?? [];

    return questions.map((question) => ({
      id: `${question.question}-${question.answer}`,
      ...question
    }));
  }, [data, selectedCategory]);

  useEffect(() => {
    if (data) {
      setSelectedCategory(data[0].id);
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <GridContainer sx={{ paddingY: 12, minHeight: '90vh' }} contentSpacing={4}>
        <GridItem xs={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} md={10} lg={8} xl={8}>
              <FlexCol>
                <Typography
                  variant='h1'
                  fontSize={28}
                  fontWeight={theme.typography.fontWeightBold}
                  letterSpacing={-0.56}
                  lineHeight={1.5}
                >
                  {t('frequently-asked-questions')}
                </Typography>

                <Typography
                  fontSize={14}
                  lineHeight={1.5}
                  maxWidth='sm'
                  variant='h2'
                  marginTop={4}
                  color={theme.palette.customColor.gray.dark}
                  letterSpacing={-0.28}
                >
                  {t('faq-page-description')}
                </Typography>

                <Typography
                  fontSize={14}
                  lineHeight={1.5}
                  maxWidth='sm'
                  letterSpacing={-0.28}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleEmailClick}
                  marginTop={2}
                >
                  {t('contact-us')}
                </Typography>

                <FlexCol alignItems='flex-start'>
                  <Typography
                    marginTop={8}
                    marginBottom={2}
                    fontSize={12}
                    fontWeight={theme.typography.fontWeightBold}
                    color={theme.palette.customColor.gray.dark}
                    textTransform='uppercase'
                  >
                    {t('categories')}:
                  </Typography>

                  {isLoading && <CircularProgress sx={{ marginY: 30 }} />}

                  {data?.map((faq) =>
                    <Button
                      key={faq.id}
                      sx={{
                        border: 1,
                        width: '100%',
                        borderColor: theme.palette.customColor.border.light,
                        backgroundColor: selectedCategory === faq.id ? '#0000000D' : 'white',
                        justifyContent: 'flex-start',
                        marginBottom: 2,
                        borderRadius: '10px',
                        paddingLeft: 4
                      }}
                      onClick={() => setSelectedCategory(faq.id)}
                      endIcon={
                        <ChevronRightIcon style={{ width: 22, height: 22, color: '#BBBBBB' }} />
                      }
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={1.5}
                        letterSpacing={-0.28}
                        color={theme.palette.customColor.gray.dark}
                        textTransform='none'
                        flex={1}
                        textAlign='left'
                        fontWeight={
                          selectedCategory === faq.id
                            ? theme.typography.fontWeightBold
                            : theme.typography.fontWeightRegular
                        }
                      >
                        {faq.category}
                      </Typography>
                    </Button>
                  )}
                </FlexCol>
              </FlexCol>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} lg={6}>
          <GridContainer>
            <GridItem xs={12}>
              <FaqList
                questions={selectedQuestions}
                hideDivider
                setSx={(expanded) => ({
                  padding: 4,
                  border: 1,
                  borderColor: expanded
                    ? theme.palette.primary.main
                    : theme.palette.customColor.border.light,
                  marginBottom: 2
                })}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </PageContainer>
  );
};

export default FaqPage;
