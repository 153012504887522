import { ContainerProps, Container } from '@mui/material';
import React from 'react';

import Footer from '../data-display/Footer';
import { NavigationBar, NavigationBarProps } from '../navigation/NavigationBar';

interface PageProps {
  children: React.ReactNode;
  sx?: ContainerProps['sx'];
  containerProps?: ContainerProps;
  hideNavigationBar?: boolean;
  navigationBarProps?: NavigationBarProps;
}

const PageContainer = ({
  children,
  sx = {},
  containerProps = {},
  hideNavigationBar,
  navigationBarProps = {}
}: PageProps) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ backgroundColor: '#FFFFFF', ...sx }}
      {...containerProps}
    >
      {!hideNavigationBar && <NavigationBar {...navigationBarProps} />}

      {children}

      <Footer />
    </Container>
  );
};

export default PageContainer;
