import { useQuery } from '@tanstack/react-query';
import { query, collection, getDocs } from 'firebase/firestore';

import { db } from '../config/firebase';

export interface IFaq {
  id: string;
  category: string;
  questions: { question: string; answer: string }[];
}

const getFaq = async () => {
  const faqQuery = query(collection(db, 'faq'));

  const faqDocs = await getDocs(faqQuery);

  if (faqDocs?.docs) {
    return faqDocs.docs.map((doc) => ({ ...(doc.data() as IFaq), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetFaq = () =>
  useQuery({
    queryKey: ['faq'],
    queryFn: () => getFaq()
  });
