import { Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchBox from '../../components/data-display/SearchBox';
import ToolList from '../../components/data-display/ToolList';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import useSearchTools from '../../libs/api/hooks/useSearchTools';
import { getFavoriteTools } from '../../libs/redux/slices/tools/toolsSelectors';
import { ROUTES } from '../../libs/router/config/routes';

const SearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('q') || '';

  const [searchValue, setSearchValue] = useState(searchQuery);

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(() => favoriteTools.map((tool) => tool.id), [favoriteTools]);

  const { data: tools, isLoading } = useSearchTools(searchQuery);

  const setSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearch = useCallback(() => {
    navigate(`${ROUTES.SEARCH_PAGE}?q=${encodeURIComponent(searchValue)}`);
  }, [navigate, searchValue]);

  return (
    <PageContainer>
      <GridContainer sx={{ paddingY: 12 }}>
        <GridItem xs={12}>
          <Typography
            maxWidth='sm'
            fontSize={28}
            letterSpacing={-0.56}
            fontWeight={theme.typography.fontWeightBold}
            marginBottom={4}
          >
            {t('search')}
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <SearchBox
            value={searchValue}
            onChange={setSearch}
            onClickSearch={onClickSearch}
            sx={{ marginBottom: 8, marginTop: 4 }}
          />
        </GridItem>

        <ToolList tools={tools} favoriteToolsIds={favoriteToolsIds} isLoading={isLoading} />
      </GridContainer>
    </PageContainer>
  );
};

export default SearchPage;
