import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, styled, Typography, useTheme } from '@mui/material';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';

import { ImageIcon, StarIcon } from '../../assets/icons';
import { ITool } from '../../libs/api/hooks/useGetTool';
import { useToggle } from '../../libs/hooks/utils/use-toggle';
import { useActionDispatch } from '../../libs/redux/root/store';
import {
  addToolToFavorites,
  removeToolFromFavorites
} from '../../libs/redux/slices/tools/toolsActions';
import { getCategoryPageLink, getToolPageLink } from '../../libs/router/config/routes';
import FavoriteButton from '../buttons/FavoriteButton';
import { FlexCol } from '../layout/FlexCol';
import { FlexRow } from '../layout/FlexRow';
import { LinkBox } from '../styled/LinkBox';

interface ProductCardProps {
  tool: ITool;
  isFavorite?: boolean;
  removeCanonical?: boolean;
}

const ToolCard = ({ tool, isFavorite, removeCanonical }: ProductCardProps) => {
  const dispatch = useActionDispatch();

  const { t } = useTranslation();

  const theme = useTheme();

  const [isFavoriteTool, toggleFavoriteTool] = useToggle(isFavorite);

  const onClickFavorite = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      toggleFavoriteTool();

      if (isFavorite) {
        dispatch(removeToolFromFavorites(tool.id));
      } else {
        dispatch(addToolToFavorites(tool));
      }
    },
    [dispatch, isFavorite, toggleFavoriteTool, tool]
  );

  const backgroundImageUrl = useMemo(() => {
    if (tool.thumbnail) {
      if (tool.thumbnail.includes(' ')) {
        return encodeURI(tool.thumbnail);
      }

      return tool.thumbnail;
    }

    if (tool.image) {
      if (tool.image.includes(' ')) {
        return encodeURI(tool.image);
      }

      return tool.image;
    }

    return null;
  }, [tool.image, tool.thumbnail]);

  const linBoxProps = useMemo(() => {
    if (removeCanonical) {
      return {
        to: getToolPageLink(tool.category.id, tool.id)
      };
    } else {
      return {
        to: getToolPageLink(tool.category.id, tool.id),
        rel: 'canonical'
      };
    }
  }, [removeCanonical, tool]);

  return (
    <ProductContainer
      sx={tool.recommended ? { borderColor: theme.palette.primary.main } : {}}
      itemScope
      itemType='https://schema.org/ListItem'
    >
      <LinkBox {...linBoxProps}>
        <FlexCol sx={{ position: 'relative' }}>
          <ProductImage
            sx={{
              backgroundImage: `url(${backgroundImageUrl})`,
              backgroundColor: backgroundImageUrl ? undefined : '#00000010'
            }}
          />

          <FlexRow
            justifyContent='space-between'
            sx={{ top: 0, position: 'absolute', width: '100%' }}
          >
            <FlexRow sx={{ gap: 2, padding: 2 }}>
              {tool.recommended ?
                <Box>
                  <Typography
                    fontSize={12}
                    letterSpacing={-0.24}
                    color={theme.palette.primary.main}
                    fontWeight={theme.typography.fontWeightBold}
                    sx={{
                      borderRadius: '5px',
                      paddingX: 2,
                      paddingY: 1,
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    {t('recommended')}
                  </Typography>
                </Box>
                :
                <Box />
              }

              {!!tool.price &&
                <Box>
                  <Typography
                    fontSize={12}
                    letterSpacing={-0.24}
                    color='#000000'
                    fontWeight={theme.typography.fontWeightBold}
                    sx={{
                      borderRadius: '5px',
                      paddingX: 2,
                      paddingY: 1,
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    {`${tool.price}`}
                  </Typography>
                </Box>
              }
            </FlexRow>

            <FavoriteButton
              sx={{ margin: 2 }}
              onClick={onClickFavorite}
              isFavorite={isFavoriteTool}
            />
          </FlexRow>

          {!tool.thumbnail &&
            <ImageIcon
              style={{
                width: 32,
                height: 32,
                color: theme.palette.customColor.mediumGray.main,
                position: 'absolute',
                top: '50%',
                left: 'calc(50% - 16px)'
              }}
            />
          }
        </FlexCol>

        <FlexCol sx={{ padding: 4 }} gap={2}>
          <FlexRow sx={{ alignItems: 'flex-start' }}>
            <Typography fontSize={16} fontWeight={theme.typography.fontWeightBold}>
              {tool.title}
            </Typography>

            {!!tool.company &&
              <VerifiedIcon sx={{ color: '#42A5F5', marginLeft: 1, width: 22, height: 22 }} />
            }
          </FlexRow>

          <LinesEllipsis
            text={tool.short_description}
            maxLine={3}
            ellipsis='...'
            trimRight
            basedOn='letters'
            style={{
              fontSize: 14,
              lineHeight: '20px',
              color: theme.palette.customColor.gray.dark,
              minHeight: 60
            }}
          />

          <FlexRow alignItems='center' gap={2} sx={{ marginTop: 2 }}>
            {tool.category?.id && tool.category_name?.length > 0 &&
              <LinkBox to={getCategoryPageLink(tool.category.id)}>
                <ToolCategoryContainer>{tool.category_name}</ToolCategoryContainer>
              </LinkBox>
            }

            {!!tool.rating &&
              <FlexRow alignItems='center' gap={1}>
                <StarIcon
                  style={{
                    color: theme.palette.customColor.yellow.main,
                    fill: theme.palette.customColor.yellow.main,
                    width: 18,
                    height: 18
                  }}
                />

                <Typography fontSize={14}>{tool.rating}</Typography>
              </FlexRow>
            }
          </FlexRow>
        </FlexCol>
      </LinkBox>
    </ProductContainer>
  );
};

export default ToolCard;

const ProductContainer = styled(Box)(({ theme }) => ({
  borderRadius: '9px',
  backgroundColor: '#FFFFFF',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  border: '1px solid',
  borderColor: theme.palette.customColor.border.light,
  transition: 'box-shadow 0.2s',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)'
  }
}));

const ProductImage = styled(Box)(() => ({
  height: 160,
  width: '100%',
  backgroundPosition: 'top left',
  backgroundSize: 'cover',
  position: 'relative',
  borderColor: '0px solid',
  filter: 'brightness(0.9)'
}));

const ToolCategoryContainer = styled(Box)(() => ({
  backgroundColor: '#F2F2F2',
  borderRadius: '10px',
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 6,
  paddingBottom: 6,
  fontSize: 12,
  '&:hover': {
    backgroundColor: '#E5E5E5'
  }
}));
