import { useQuery } from '@tanstack/react-query';
import { doc, getDoc, DocumentData, DocumentReference } from 'firebase/firestore';

import { db } from '../config/firebase';

export enum TOOL_STATUS {
  CONVERT_JPG = 'ConvertJPG',
  NEW = 'New',
  PRIVATE = 'Private',
  PUBLIC = 'Public',
  SCREENSHOT = 'Screenshot',
  SCREENSHOT_COOKIE = 'ScreenshotCookie',
  SCREENSHOT_ERROR = 'ScreenshotError',
  UNPUBLISHED = 'Unpublished',
  VERIFY = 'Verify'
}

interface ILocation {
  city?: string;
  country?: string;
  country_code?: string;
  latitude?: number;
  longitude?: number;
  postcode?: number;
  region?: string;
  street?: string;
  street_number?: string;
}

export interface IToolCompany {
  country?: string;
  employee_count?: number;
  estimated_revenue?: number;
  location: ILocation;
  main_business_category: string;
  name: string;
  primary_email?: string;
  primary_phone?: string;
  type?: string;
  year_founded?: number;
}

export interface IVeridion {
  last_updated_at?: string;
  long_description?: string;
  match_score?: number;
  short_description?: string;
}

export interface ITool extends DocumentData {
  id: string;
  category: DocumentReference;
  status: DocumentReference<{status: TOOL_STATUS}>;
  recommended?: boolean;
  category_name: string;
  company?: IToolCompany;
  description: string;
  price?: string;
  legal_names?: string[];
  short_description?: string;
  social_links?: string[];
  title: string;
  veridion?: boolean;
  veridion_data?: IVeridion;
  website?: string;
  thumbnail?: string;
  image?: string;
  score?: number;
  rating?: number;
  cost?: string;
}

const getToolById = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const toolRef = doc(db, 'tool', toolId);
  const toolDoc = await getDoc(toolRef);

  if (toolDoc.exists()) {
    return { ...toolDoc.data(), id: toolDoc.id } as ITool;
  } else {
    throw new Error('No such document!');
  }
};

export const useGetTool = (toolId?: string) =>
  useQuery({
    queryKey: ['tool', toolId],
    queryFn: () => getToolById(toolId),
    enabled: !!toolId
  });
