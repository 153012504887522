import { Typography } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CategoriesPageMeta from './components/CategoriesPageMeta';
import CategoriesList from '../../components/data-display/CategoriesList';
import SearchBox from '../../components/data-display/SearchBox';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import { useGetCategories } from '../../libs/api/hooks/useGetCategories';
import { ROUTES } from '../../libs/router/config/routes';

const clearString = (str?: string) => str?.toLowerCase().replace(/ /g, '') || '';

const CategoriesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('q') || '';

  const [searchValue, setSearchValue] = useState(searchQuery);

  const { data: allCategories, isLoading } = useGetCategories();

  const setSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
      navigate(`${ROUTES.CATEGORIES_PAGE}?q=${encodeURIComponent(event.target.value)}`);
    } else {
      navigate(`${ROUTES.CATEGORIES_PAGE}`);
    }

  }, []);

  const categories = useMemo(() => {
    if (searchValue?.length) {
      return allCategories?.filter(
        (category) =>
          clearString(category.category).includes(clearString(searchValue)) ||
          clearString(category.description).includes(clearString(searchValue))
      );
    }

    return allCategories;
  }, [allCategories, searchValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <CategoriesPageMeta />

      <GridContainer sx={{ paddingY: 12 }} itemScope itemType='https://schema.org/ItemList'>
        <GridItem xs={12}>
          <FlexCol sx={{ alignItems: 'center', marginBottom: 8 }}>
            <Typography
              maxWidth='sm'
              variant='h1'
              fontSize={24}
              fontWeight='400'
              letterSpacing='1px'
              textAlign='center'
              lineHeight={2}
            >
              {t('categories')}
            </Typography>

            <Typography
              maxWidth='md'
              fontSize={16}
              lineHeight={1.5}
              textAlign='center'
              variant='h2'
              marginTop={4}
              color='gray'
              fontWeight='400'
              zIndex={1}
              letterSpacing='1px'
              marginBottom={12}
            >
              {t('browse-categories')}
            </Typography>

            <SearchBox
              value={searchValue}
              onChange={setSearch}
              placeholder={t('search-categories') || ''}
            />
          </FlexCol>
        </GridItem>

        <GridItem xs={12}>
          {categories?.length ?
            <CategoriesList categories={categories} isLoading={isLoading} />
            :
            <FlexCol sx={{ alignItems: 'center' }}>
              <Typography fontSize={32} marginBottom='16px'>
                {t('oops')}!
              </Typography>
              <Typography fontSize={24}>{t('no-categories-found')}</Typography>
            </FlexCol>
          }
        </GridItem>
      </GridContainer>
    </PageContainer>
  );
};

export default CategoriesPage;
