import { Divider, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LandingPageMeta from './components/LandingPageMeta';
import backgroundImage from '../../assets/images/landing-background.png';
import SearchBox from '../../components/data-display/SearchBox';
import ToolList from '../../components/data-display/ToolList';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import HorizontalCategoriesList from '../../components/list/HorizontalCategoriesList';
import { ShowMoreButton } from '../../components/styled/ShowMoreButton';
import { TOOLS_FILTER_KEYS, TOOLS_FILTERS } from '../../libs/api/helpers/getToolsFilterBy';
import { useGetHighlightedCategories } from '../../libs/api/hooks/useGetHighlightedCategories';
import { useGetTools } from '../../libs/api/hooks/useGetTools';
import { useSetUserSearchQuery } from '../../libs/api/hooks/useSetUserSearchQuery';
import { getFavoriteTools } from '../../libs/redux/slices/tools/toolsSelectors';
import { ROUTES } from '../../libs/router/config/routes';

const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<TOOLS_FILTER_KEYS>(TOOLS_FILTER_KEYS.ALL);

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(() => favoriteTools.map((tool) => tool.id), [favoriteTools]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: tools,
    count,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useGetTools({
    filterBy: selectedFilter
  });

  const { data: categories } = useGetHighlightedCategories();
  const { mutate: saveSearchQuery } = useSetUserSearchQuery();

  const onClickShowMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const setSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearch = useCallback(() => {
    navigate(`${ROUTES.SEARCH_PAGE}?q=${encodeURIComponent(searchValue)}`);
    saveSearchQuery(searchValue);
  }, [navigate, saveSearchQuery, searchValue]);

  if (isLoading || !tools) {
    return <LinearProgress sx={{ marginY: 30 }} />;
  }

  return (
    <PageContainer navigationBarProps={{ searchBox: 'onScroll' }}>
      <LandingPageMeta toolsCount={count} />

      <GridContainer sx={{ paddingY: 8 }} contentSpacing={8}>
        <GridItem xs={12}>
          <FlexCol sx={{ alignItems: 'center' }}>
            <FlexCol
              sx={{
                background: `transparent url(${backgroundImage}) 0% 0% no-repeat padding-box`,
                backgroundSize: 'cover',
                width: '100%',
                justifyContent: 'center',
                borderRadius: '10px',
                overflow: 'hidden',
                border: 1,
                paddingY: 4,
                ...!isSmallScreen ? { height: 360 } : {}
              }}
            >
              <GridContainer>
                <GridItem xs={12}>
                  <Typography
                    maxWidth='sm'
                    variant='h1'
                    fontSize={isSmallScreen ? 22 : 50}
                    fontWeight={theme.typography.fontWeightBold}
                    color='#FFFFFF'
                    letterSpacing={isSmallScreen ? 0 : -1}
                    lineHeight={isSmallScreen ? 2 : 1.1}
                  >
                    {t('hello-human')}
                  </Typography>

                  <Typography
                    maxWidth='sm'
                    variant='h1'
                    fontSize={isSmallScreen ? 22 : 50}
                    fontWeight={theme.typography.fontWeightBold}
                    color='#FFFFFF'
                    letterSpacing={isSmallScreen ? 0 : -1}
                    lineHeight={1.1}
                  >
                    {t('early-to-ai-playground')}
                  </Typography>

                  <Typography
                    maxWidth='sm'
                    fontSize={isSmallScreen ? 14 : 18}
                    lineHeight={1.5}
                    variant='h2'
                    marginTop={4}
                    color='#FFFFFF'
                    fontWeight='400'
                    zIndex={1}
                    letterSpacing={isSmallScreen ? 0 : -0.36}
                  >
                    {t('guaranteed-match')}
                  </Typography>
                </GridItem>
              </GridContainer>
            </FlexCol>
          </FlexCol>
        </GridItem>

        <GridItem xs={10}>
          <SearchBox
            value={searchValue}
            onChange={setSearch}
            onClickSearch={onClickSearch}
            placeholder={t('search-tool') || ''}
            setFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            filters={Object.values(TOOLS_FILTERS)}
          />

          {!!categories?.length &&
            <HorizontalCategoriesList sx={{ marginTop: 4 }} categories={categories} />
          }
        </GridItem>
      </GridContainer>

      <Divider sx={{ backgroundColor: '#F2F2F2' }} />

      <GridContainer sx={{ paddingY: 8 }} contentSpacing={8}>
        <GridItem xs={12}>
          <Typography
            fontSize={28}
            letterSpacing={-0.56}
            fontWeight={theme.typography.fontWeightBold}
            marginBottom={8}
          >
            {`${t(TOOLS_FILTERS[selectedFilter].name)} ${count ? `(${count})` : ''}`}
          </Typography>
          <ToolList tools={tools} favoriteToolsIds={favoriteToolsIds} isLoading={isLoading} />
        </GridItem>

        {hasNextPage && !isLoading &&
          <GridItem xs={12}>
            <FlexCol sx={{ alignItems: 'center' }}>
              <ShowMoreButton variant='contained' onClick={onClickShowMore}>
                {t('show-more-ai-tools')}
              </ShowMoreButton>
            </FlexCol>
          </GridItem>
        }
      </GridContainer>
    </PageContainer>
  );
};

export default LandingPage;
