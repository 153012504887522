
import { createTheme } from '@mui/material/styles';

import { components } from './config/components';
import { palette } from './config/palette';
import { shape } from './config/shape';
import { typography } from './config/typography';

export const theme = createTheme({
  spacing: 4,
  typography,
  palette,
  shape,
  components
});
