import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITool } from '../../../api/hooks/useGetTool';

export interface ToolsState {
  favorites: ITool[];
  loading: boolean;
  error: string | null;
}

const initialState: ToolsState = {
  favorites: [],
  loading: false,
  error: null
};

const SLICE_NAME = 'TOOLS';

const toolsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFavorites: (state, action: PayloadAction<ITool[]>) => {
      state.favorites = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoadingStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    favoritesFailure: (state, action: PayloadAction<string>) => {
      state.favorites = [];
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { reducer: ToolsReducer, actions: ToolsActions } = toolsSlice;

export const { setFavorites, setLoadingStart, favoritesFailure } = toolsSlice.actions;
