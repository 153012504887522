import { useQuery } from '@tanstack/react-query';
import {
  query,
  collection, getDocs
} from 'firebase/firestore';

import { db } from '../config/firebase';

export interface IUsecase {
  id: string;
  name: string;
  description: string;
}

const getToolUsecases = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const usecasesQuery = query(
    collection(db, 'tool', toolId, 'usecases'),
  );

  const usecasesDocs = await getDocs(usecasesQuery);

  if (usecasesDocs?.docs) {
    return usecasesDocs.docs.map(doc => ({ ...(doc.data() as IUsecase), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetToolUsecases = (toolId?: string) =>
  useQuery({
    queryKey: ['toolUsecases', toolId],
    queryFn: () => getToolUsecases(toolId),
    enabled: !!toolId
  });
