import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LinkBox = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
