import VerifiedIcon from '@mui/icons-material/Verified';
import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { StarIcon } from '../../../assets/icons';
import { FlexRow } from '../../../components/layout/FlexRow';
import { LinkBox } from '../../../components/styled/LinkBox';
import { getCategoryPageLink } from '../../../libs/router/config/routes';

interface ToolTitleProps {
  title: string;
  verified: boolean;
  categoryName: string;
  rating?: number;
  categoryId?: string;
}

const ToolTitle = ({ title, verified, rating, categoryName, categoryId }: ToolTitleProps) => {
  const theme = useTheme();

  return (
    <FlexRow sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <FlexRow sx={{ alignItems: 'center' }}>
        <Typography
          itemProp='name'
          fontSize={36}
          lineHeight='54px'
          fontWeight='600'
          paddingRight={4}
          variant='h1'
        >
          {title}
        </Typography>

        {verified &&
          <VerifiedIcon sx={{ color: '#42A5F5', marginRight: 2, width: 24, height: 24 }} />
        }
      </FlexRow>

      <FlexRow sx={{ alignItems: 'center' }}>
        {categoryName && categoryId &&
          <LinkBox
            to={getCategoryPageLink(categoryId)}
            sx={{ backgroundColor: '#F2F2F2', borderRadius: 10, marginRight: 4 }}
          >
            <Typography fontSize={14} paddingY={2} paddingX={4}>
              {categoryName}
            </Typography>
          </LinkBox>
        }

        {!!rating &&
          <FlexRow sx={{ alignItems: 'center' }}>
            <Typography fontSize={14} fontWeight='600' marginRight={1}>
              {rating}
            </Typography>

            <StarIcon
              style={{
                color: theme.palette.customColor.yellow.main,
                fill: theme.palette.customColor.yellow.main,
                width: 22,
                height: 22
              }}
            />
          </FlexRow>
        }
      </FlexRow>
    </FlexRow>
  );
};

export default ToolTitle;
