import { CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ITool } from '../../libs/api/hooks/useGetTool';
import ToolCard from '../cards/ToolCard';
import { FlexCol } from '../layout/FlexCol';
import GridItem from '../layout/GridItem';

interface ToolListProps {
  tools: ITool[];
  favoriteToolsIds?: string[];
  isLoading?: boolean;
  removeCanonical?: boolean;
}

const ToolList = ({ tools, favoriteToolsIds, isLoading, removeCanonical }: ToolListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (isLoading) {
    return (
      <GridItem xs={12}>
        <FlexCol sx={{ alignItems: 'center' }}>
          <CircularProgress sx={{ marginY: 30 }} />
        </FlexCol>
      </GridItem>
    );
  }

  if (!isLoading && !tools.length) {
    return (
      <GridItem xs={12}>
        <Typography
          textAlign='center'
          fontSize={32}
          fontWeight={theme.typography.fontWeightBold}
          sx={{ marginY: 4 }}
          letterSpacing={-0.28}
        >
          {t('hmm')}
        </Typography>
        <Typography textAlign='center' letterSpacing={-0.28} fontSize={18}>
          {t('no-tool-matching')}
        </Typography>
      </GridItem>
    );
  }

  return (
    <Grid container columns={12} spacing={6}>
      {tools.map((tool) =>
        <GridItem key={tool.id} xs={12} sm={6} md={4} lg={3}>
          <ToolCard
            tool={tool}
            isFavorite={favoriteToolsIds?.includes(tool.id)}
            removeCanonical={removeCanonical}
          />
        </GridItem>
      )}
    </Grid>
  );
};

export default ToolList;
