import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { setDoc, serverTimestamp, doc } from 'firebase/firestore';
import { useCallback } from 'react';

import { db } from '../config/firebase';

const useSubscribeToNewsletter = (onSuccess: () => void) => {
  const subscribeToNewsletter = useCallback(async (email: string) => {
    try {
      const data = {
        email: email,
        date: serverTimestamp()
      };

      setDoc(doc(db, 'subscribed_email', email), data);
    } catch (error) {
      return null;
    }

    try {
      await axios.post('https://subscribetonewsletter-l7o6tldk5a-uc.a.run.app', { email });
    } catch (error) {
      return null;
    }
  }, []);

  return useMutation(subscribeToNewsletter, {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    }
  });
};

export default useSubscribeToNewsletter;
