import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { StarIcon } from '../../../assets/icons';
import { FlexRow } from '../../../components/layout/FlexRow';

interface ReviewStarsProps {
  rating: number;
  sx?: BoxProps;
  numberOfReviews?: number;
  hideRatingValue?: boolean;
}

const ReviewStars = ({ rating, sx = {}, numberOfReviews, hideRatingValue }: ReviewStarsProps) => {
  const theme = useTheme();

  const stars = useMemo(() => {
    const emptyStars = Array(5).fill(false);
    const filledStars = Math.round(rating);

    for (let i = 0; i < filledStars; i++) {
      emptyStars[i] = true;
    }

    return emptyStars;
  }, [rating]);

  return (
    <FlexRow
      sx={[{ alignItems: 'center', height: '20px' }, sx]}
      gap={2}
      itemProp='reviewRating'
      itemScope
      itemType='http://schema.org/Rating'
    >
      {stars.map((filled, index) =>
        <Box key={index}>
          {filled ?
            <StarIcon
              style={{
                color: theme.palette.customColor.yellow.main,
                fill: theme.palette.customColor.yellow.main,
                width: 18,
                height: 18
              }}
            />
            :
            <StarIcon
              style={{
                color: theme.palette.customColor.gray.main,
                width: 18,
                height: 18
              }}
            />
          }
        </Box>
      )}

      {!hideRatingValue &&
        <Typography color='gray' fontSize={14} fontWeight='600'>
          {`${rating}`}
          {numberOfReviews ? ` (${numberOfReviews} reviews)` : ''}
        </Typography>
      }

      <meta property='og:rating' itemProp='ratingValue' content={rating.toString()} />
    </FlexRow>
  );
};

export default ReviewStars;
