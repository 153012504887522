import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded';
import { Box, Card, IconButton, styled } from '@mui/material';
import React, { useState } from 'react';


interface IYouTubeVideoProps {
  videoUrl: string;
}

const YouTubeVideo = ({ videoUrl }: IYouTubeVideoProps) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  const getThumbnailUrl = (url: string): string => {
    const videoId = url.split('v=')[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const handlePlayVideo = () => {
    window.open(videoUrl, '_blank');
  };

  React.useEffect(() => {
    setThumbnailUrl(getThumbnailUrl(videoUrl));
  }, [videoUrl]);

  return (
    <ImageCard>
      {thumbnailUrl &&
        <Image onClick={handlePlayVideo} src={thumbnailUrl} alt={`ai tool ${videoUrl}`} />
      }
      <Overlay>
        <IconButton
          href={videoUrl}
          target='_blank'
          sx={{
            textTransform: 'none',
            color: 'white',
            position: 'relative'
          }}
        >
          <PlayCircleFilledWhiteRoundedIcon sx={{ width: '48px', height: '48px' }}/>
        </IconButton>
      </Overlay>
    </ImageCard>
  );
};

export default YouTubeVideo;

const ImageCard = styled(Card)(() => ({
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  marginTop: 4,
  '&:hover img': {
    filter: 'brightness(0.5)',
    cursor: 'pointer'
  }
}));

const Image = styled('img')({
  height: 'auto',
  width: '100%',
  objectFit: 'contain',
  borderRadius: 2,
  filter: 'brightness(0.8)',
  transition: 'filter 0.3s ease'
});

const Overlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
