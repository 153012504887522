export enum ROUTES {
  ROOT = 'https://www.youraitool.com',
  NOT_FOUND_PAGE = '*',
  LANDING_PAGE = '/',
  RECOMMENDED_TOOLS_PAGE = '/recommended-tools',
  TOOLS_PAGE = '/tools',
  CATEGORIES_PAGE = '/categories',
  SUBMIT_TOOL_PAGE = '/submit-tool',
  FAVORITES_PAGE = '/favorites',
  AI_NEWS_PAGE = '/ai-news',
  FAQ_PAGE = '/faq',
  SEARCH_PAGE = '/search',
  BLOG_URL = 'https://www.youraitool.com/blog'
}

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://www.youraitool.com';

export const BASE_PATH = 'youraitool.com';

export const getToolPageLink = (categoryId: string, toolId: string, canonical?: boolean):string => {
  const base = canonical ? BASE_PATH : BASE_URL;
  return `${base}${ROUTES.TOOLS_PAGE}/${categoryId}/${toolId}`;
};

export const getToolCategoryPageLink = (categoryId: string, canonical?: boolean):string => {
  const base = canonical ? BASE_PATH : BASE_URL;
  return `${base}${ROUTES.TOOLS_PAGE}/${categoryId}`;
};

export const getCategoryPageLink = (categoryId: string, canonical?: boolean):string => {
  const base = canonical ? BASE_PATH : BASE_URL;
  return `${base}${ROUTES.CATEGORIES_PAGE}/${categoryId}`;
};

export const getLandingPageLink = (canonical?: boolean):string => {
  const base = canonical ? BASE_PATH : BASE_URL;
  return `${base}${ROUTES.LANDING_PAGE}`;
};

export const getRecommendedToolsPageLink = () => `${BASE_URL}${ROUTES.RECOMMENDED_TOOLS_PAGE}`;

export const getCategoriesPageLink = (canonical?: boolean):string => {
  const base = canonical ? BASE_PATH : BASE_URL;
  return `${base}${ROUTES.CATEGORIES_PAGE}`;
};

export const getFaqPageLink = () => `${BASE_URL}${ROUTES.FAQ_PAGE}`;

export const getSubmitToolPageLink = () => `${BASE_URL}${ROUTES.SUBMIT_TOOL_PAGE}`;

export const getFavoritesPageLink = () => `${BASE_URL}${ROUTES.FAVORITES_PAGE}`;
