import { Button, styled } from '@mui/material';

export const ShowMoreButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '10px',
  fontSize: 14,
  color: '#FFFFFF',
  fontWeight: '600',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 12,
  paddingBottom: 12,
  overflow: 'hidden',
  marginTop: 32
}));
