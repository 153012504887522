import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../config/firebase';

export interface ICategory {
  id: string;
  category: string;
  tools: number;
  description?: string;
}

const getCategoryById = async (categoryId?: string) => {
  if (!categoryId) {
    throw new Error('No category id!');
  }

  const categoryRef = doc(db, 'categories', categoryId);
  const categoryDoc = await getDoc(categoryRef);

  if (categoryDoc.exists()) {
    return categoryDoc.data() as ICategory;
  } else {
    throw new Error('No such document!');
  }
};

export const useGetCategoryById = (categoryId?: string) =>
  useQuery({
    queryKey: ['category', categoryId],
    queryFn: () => getCategoryById(categoryId),
    enabled: !!categoryId,
    retry: 2
  });
