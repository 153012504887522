import { useInfiniteQuery } from '@tanstack/react-query';
import {
  collection,
  getDocs,
  query,
  startAfter,
  limit,
  where,
  DocumentReference
} from 'firebase/firestore';
import { useMemo } from 'react';

import { ITool } from './useGetTool';
import { db } from '../config/firebase';

const PAGE_LIMIT = 24;

const getTools = async (category?: DocumentReference | string, lastDocSnapshot = null) => {
  let toolsQuery;
  const whereStatement =
    typeof category === 'string'
      ? where('category_name', '==', category)
      : where('category', '==', category);

  if (lastDocSnapshot) {
    toolsQuery = query(
      collection(db, 'tool'),
      whereStatement,
      startAfter(lastDocSnapshot),
      // orderBy('score', 'desc'),
      limit(PAGE_LIMIT)
    );
  } else {
    toolsQuery = query(
      collection(db, 'tool'),
      whereStatement,
      // orderBy('score', 'desc'),
      limit(PAGE_LIMIT)
    );
  }

  const toolSnapshot = await getDocs(toolsQuery);

  return {
    tools: toolSnapshot.docs.map((doc) => ({ ...(doc.data() as ITool), id: doc.id })),
    nextPage: toolSnapshot.docs.length ? toolSnapshot.docs[toolSnapshot.docs.length - 1] : null
  };
};

export const useGetToolsByCategory = (category?: DocumentReference | string, toolId?: string) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['tool', category, toolId],
    queryFn: ({ pageParam = null }) => getTools(category, pageParam),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true,
    enabled: !!category
  });

  const tools = useMemo(() => {
    if (!data) {
      return [];
    }

    if (toolId) {
      return data.pages.flatMap((page) => page.tools).filter((tool) => tool.id !== toolId);
    } else {
      return data.pages.flatMap((page) => page.tools);
    }
  }, [data, toolId]);

  return {
    data: tools,
    ...rest
  };
};
