import { useInfiniteQuery } from '@tanstack/react-query';
import { collection, getDocs, query, orderBy, startAfter, limit, Timestamp } from 'firebase/firestore';

import { db } from '../config/firebase';

export interface IToolReview {
  id: string;
  rating: number;
  text: string;
  user: string;
  date: Timestamp;
}

const PAGE_LIMIT = 20;

const getToolReviews = async (toolId = '', lastReviewSnapshot = null) => {
  let reviewsQuery;

  if (lastReviewSnapshot) {
    reviewsQuery = query(
      collection(db, 'tool', toolId, 'reviews'),
      orderBy('date'),
      startAfter(lastReviewSnapshot),
      limit(PAGE_LIMIT)
    );
  } else {
    reviewsQuery = query(
      collection(db, 'tool', toolId, 'reviews'),
      orderBy('date'),
      limit(PAGE_LIMIT)
    );
  }

  const reviewSnapshot = await getDocs(reviewsQuery);

  return {
    reviews: reviewSnapshot.docs.map((doc) => ({ ...(doc.data() as IToolReview), id: doc.id })),
    nextPage: reviewSnapshot.docs.length
      ? reviewSnapshot.docs[reviewSnapshot.docs.length - 1]
      : null
  };
};

export const useGetToolReviews = (toolId?: string) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['toolReviews', toolId],
    queryFn: ({ pageParam = null }) => getToolReviews(toolId, pageParam),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true,
    enabled: !!toolId
  });

  return {
    data: data?.pages.flatMap((page) => page.reviews) ?? [],
    ...rest
  };
};
