import { Button, Divider, IconButton, SwipeableDrawer, useTheme } from '@mui/material';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SocialButtons from './SocialButtons';
import { MenuIcon } from '../../assets/icons';
import { useToggle } from '../../libs/hooks/utils/use-toggle';
import { useActionDispatch } from '../../libs/redux/root/store';
import { logoutUser } from '../../libs/redux/slices/auth/authActions';
import { getIsUserLoggedIn } from '../../libs/redux/slices/auth/authSelectors';
import {
  getCategoriesPageLink, getFaqPageLink, getFavoritesPageLink,
  getLandingPageLink,
  getRecommendedToolsPageLink, getSubmitToolPageLink
} from '../../libs/router/config/routes';
import { FlexCol } from '../layout/FlexCol';
import LoginModal from '../modals/LoginModal';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const DEFAULT_SOCIAL_LINKS = [
  'https://twitter.com/YourAITool',
  'https://discord.com/invite/XHFt4sJRnw',
  'https://www.linkedin.com/company/your-ai-tool/',
  'https://www.instagram.com/youraitool/'
];

interface UserDrawerMenuProps {
  variant: 'small' | 'normal';
}

const renderMenuOptions = (t: TFunction) =>
  <FlexCol gap={4} sx={{ width: '100%', alignItems: 'flex-start' }}>
    <Button variant='text' href={getLandingPageLink()} sx={{ color: 'gray', fontWeight: '600' }}>
      {t('all-tools')}
    </Button>

    <Divider variant='middle' orientation='horizontal' flexItem />

    <Button variant='text' href={getRecommendedToolsPageLink()} sx={{ color: 'gray', fontWeight: '600' }}>
      {t('recommended-tools')}
    </Button>

    <Divider variant='middle' orientation='horizontal' flexItem />

    <Button variant='text' href={getCategoriesPageLink()} sx={{ color: 'gray', fontWeight: '600' }}>
      {t('categories')}
    </Button>

    <Divider variant='middle' orientation='horizontal' flexItem />

    <Button variant='text' href={getFaqPageLink()} sx={{ color: 'gray', fontWeight: '600' }}>
      {t('faq')}
    </Button>

    <Divider variant='middle' orientation='horizontal' flexItem />

    <Button variant='text' href={getSubmitToolPageLink()} sx={{ color: 'gray', fontWeight: '600' }}>
      {t('submit-your-tool')}
    </Button>

    <Divider variant='middle' orientation='horizontal' flexItem />
  </FlexCol>;
export const UserDrawerMenu = ({ variant }: UserDrawerMenuProps) => {
  const dispatch = useActionDispatch();

  const { t } = useTranslation();
  const theme = useTheme();

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const [drawerOpened, toggleDrawer] = useToggle(false);
  const [loginModalOpen, toggleLoginModalOpen] = useToggle(false);

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    toggleDrawer();
  }, [dispatch, toggleDrawer]);

  return (
    <>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon style={{ width: 22, height: 22, color: theme.palette.customColor.gray.dark }} />
      </IconButton>

      <SwipeableDrawer
        anchor='right'
        open={drawerOpened}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        {isUserLoggedIn ?
          <FlexCol sx={{ flex: 1, alignItems: 'flex-start', padding: 4 }} gap={4}>
            {variant === 'small' && renderMenuOptions(t)}

            <Button
              variant='text'
              href={getFavoritesPageLink()}
              sx={{ color: 'gray', fontWeight: '600' }}
            >
              {t('favorites')}
            </Button>

            <Divider variant='middle' orientation='horizontal' flexItem />

            <Button variant='text' onClick={handleLogout} sx={{ color: 'gray', fontWeight: '600' }}>
              {t('logout')}
            </Button>
          </FlexCol>
          :
          <FlexCol sx={{ flex: 1, alignItems: 'flex-start', padding: 4 }} gap={4}>
            {renderMenuOptions(t)}

            <Button
              variant='text'
              onClick={toggleLoginModalOpen}
              sx={{ color: 'gray', fontWeight: '600' }}
            >
              {t('login')}
            </Button>
            <LoginModal open={loginModalOpen} handleClose={toggleLoginModalOpen} />

            <Divider variant='middle' orientation='horizontal' flexItem />
          </FlexCol>
        }

        <FlexCol sx={{ alignItems: 'center', paddingX: 4 }}>
          <SocialButtons socialLinks={DEFAULT_SOCIAL_LINKS} />
        </FlexCol>
      </SwipeableDrawer>
    </>
  );
};
