import { Button, Container, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../libs/router/config/routes';
import { FlexCol } from '../layout/FlexCol';

const NotFound = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onClickHome = useCallback(() => {
    navigate(ROUTES.LANDING_PAGE);
  }, [navigate]);

  return (
    <Container
      maxWidth='md'
      disableGutters
      sx={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minHeight: '100%',
        paddingY: '128px'
      }}
    >
      <FlexCol sx={{ alignItems: 'center' }}>
        <Typography fontSize={48} marginBottom='16px'>
          {t('oops')}!
        </Typography>
        <Typography fontSize={32}>{t('page-not-found')}</Typography>

        <Button
          variant='contained'
          sx={{
            backgroundColor: '#444444',
            paddingY: '4px',
            borderRadius: '16px',
            marginTop: '64px'
          }}
          onClick={onClickHome}
        >
          <Typography
            fontSize={16}
            textTransform='none'
            color='#FFFFFF'
            fontWeight='600'
            margin={3}
          >
            {t('to-main-page')}
          </Typography>
        </Button>
      </FlexCol>
    </Container>
  );
};

export default NotFound;
