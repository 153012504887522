import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

import { ICategory } from '../../libs/api/hooks/useGetCategoryById';
import CategoryCard from '../cards/CategoryCard';
import { FlexCol } from '../layout/FlexCol';
import GridItem from '../layout/GridItem';

interface CategoriesListProps {
  categories: ICategory[];
  isLoading?: boolean;
}

const CategoriesList = ({ categories, isLoading }: CategoriesListProps) => {
  if (isLoading) {
    return (
      <GridItem xs={12}>
        <FlexCol sx={{ alignItems: 'center' }}>
          <CircularProgress sx={{ marginY: 30 }} />
        </FlexCol>
      </GridItem>
    );
  }

  return (
    <Grid container spacing={4}>
      {categories.map((category) =>
        <GridItem key={category.id} xs={6} sm={6} md={4} lg={3} xl={2}>
          <CategoryCard category={category} />
        </GridItem>
      )}
    </Grid>
  );
};

export default CategoriesList;
