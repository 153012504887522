import { BoxProps, Card, Collapse, Divider, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { MinusIcon, PlusIcon } from '../../../assets/icons';
import { FlexRow } from '../../../components/layout/FlexRow';

interface FaqListProps {
  questions: {
    id: string;
    question: string;
    answer: string;
  }[];
  setSx?: (expanded: boolean) => BoxProps['sx'];
  hideDivider?: boolean;
}

const FaqList = ({ questions, setSx, hideDivider }: FaqListProps) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  const handleExpand = useCallback(
    (id: string) => {
      if (expandedId === id) {
        setExpandedId(null);
      } else {
        setExpandedId(id);
      }
    },
    [expandedId]
  );

  const getCardStyle = useCallback(
    (cardId: string) => setSx?.(expandedId === cardId),
    [expandedId, setSx]
  );

  return (
    <>
      {questions.map((faq, index) =>
        <Card
          key={faq.id}
          sx={{ boxShadow: 'none', ...getCardStyle(faq.id) }}
          onClick={() => handleExpand(faq.id)}
        >
          <FlexRow alignItems='center' justifyContent='space-between'>
            <Typography fontSize={16} fontWeight='600' lineHeight={1.5}>
              {faq.question}
            </Typography>

            <IconButton
              size='small'
              onClick={() => handleExpand(faq.id)}
              aria-expanded={expandedId === faq.id}
            >
              {expandedId === faq.id ?
                <MinusIcon style={{ width: '22px', height: '22px' }} />
                :
                <PlusIcon style={{ width: '22px', height: '22px' }} />
              }
            </IconButton>
          </FlexRow>

          <Collapse in={expandedId === faq.id}>
            <Typography
              fontSize={15}
              lineHeight={1.5}
              marginTop={2}
              marginLeft={2}
              whiteSpace='pre-line'
            >
              {faq.answer}
            </Typography>
          </Collapse>

          {index !== questions.length - 1 && !hideDivider &&
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          }
        </Card>
      )}
    </>
  );
};

export default FaqList;
