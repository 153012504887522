import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BriefcaseIcon,
  DollarIcon,
  FileIcon,
  GlobeIcon,
  LockIcon,
  MailIcon,
  PhoneIcon,
  TagIcon,
  UsersIcon
} from '../../../assets/icons';
import { FlexCol } from '../../../components/layout/FlexCol';
import { FlexRow } from '../../../components/layout/FlexRow';
import { IToolCompany } from '../../../libs/api/hooks/useGetTool';

interface ToolCompanyDataProps {
  company: IToolCompany;
}

const InfoRow = ({
  title,
  description = '-',
  icon: Icon
}: {
  icon: React.ElementType;
  title: string;
  description?: string | number;
}) => {
  const theme = useTheme();

  return (
    <FlexCol gap={1}>
      <FlexRow gap={1}>
        <Icon style={{ color: theme.palette.primary.main, width: 16, height: 16 }} />

        <Typography
          fontSize={12}
          fontWeight={theme.typography.fontWeightLight}
          color={theme.palette.customColor.gray.dark}
          textTransform='uppercase'
        >
          {title}:
        </Typography>
      </FlexRow>

      <Typography fontSize={16} fontWeight={theme.typography.fontWeightBold}>
        {description}
      </Typography>
    </FlexCol>
  );
};

const ToolCompanyData = ({ company }: ToolCompanyDataProps) => {
  const { t } = useTranslation();

  return (
    <FlexRow sx={{ flexWrap: 'wrap' }} gap={8}>
      <FlexCol gap={8}>
        <InfoRow icon={BriefcaseIcon} title={t('company-name')} description={company.name} />
        <InfoRow
          icon={TagIcon}
          title={t('main-business-category')}
          description={company.main_business_category}
        />
        <InfoRow icon={MailIcon} title={t('email')} description={company.primary_email} />
      </FlexCol>

      <FlexCol gap={8}>
        <InfoRow icon={GlobeIcon} title={t('country')} description={company.country} />
        <InfoRow
          icon={UsersIcon}
          title={t('employee-count')}
          description={!Number.isNaN(company.employee_count) ? company.employee_count : '-'}
        />
        <InfoRow
          icon={PhoneIcon}
          title={t('phone')}
          description={!Number.isNaN(company.primary_phone) ? company.primary_phone : '-'}
        />
      </FlexCol>

      <FlexCol gap={8}>
        <InfoRow
          icon={FileIcon}
          title={t('year-founded')}
          description={!Number.isNaN(company.year_founded) ? company.year_founded : '-'}
        />
        <InfoRow
          icon={DollarIcon}
          title={t('estimated-revenue')}
          description={!Number.isNaN(company.estimated_revenue) ? company.estimated_revenue : '-'}
        />
        <InfoRow icon={LockIcon} title={t('type')} description={company.type} />
      </FlexCol>
    </FlexRow>
  );
};

export default ToolCompanyData;
