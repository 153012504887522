import React from 'react';
import { Helmet } from 'react-helmet';

import { getCategoryPageLink } from '../../../libs/router/config/routes';

const CategoryPageMeta = ({
  categoryId,
  categoryName,
  categoryDescription,
  toolsCount
}: {
  categoryId?: string;
  categoryName?: string;
  categoryDescription?: string;
  toolsCount?: number;
}) => {
  return (
    <Helmet>
      <title>{`${toolsCount}+ AI-Powered ${categoryId} Tools for Enhanced Efficiency`}</title>
      <meta
        property='og:title'
        name='title'
        content={`${toolsCount}+ AI-Powered ${categoryId} Tools for Enhanced Efficiency`}
      />
      <meta
        property='og:description'
        name='description'
        content={
          categoryDescription ??
          `Discover the top picks for ${categoryName} tools ` +
            'based on Artificial Intelligence that can help you work smarter.'
        }
      />
      <link rel='canonical' href={getCategoryPageLink(categoryId ?? '', true)} />
    </Helmet>
  );
};

export default CategoryPageMeta;
