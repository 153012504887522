import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ITool, TOOL_STATUS } from './useGetTool';

interface ISearchTool extends Omit<ITool, 'category'> {
  category: {
    _path: {
      segments: string[];
    };
  };
}

const useSearchTools = (searchParam: string) => {
  const fetchTools = async ({ pageParam = 0 }): Promise<ISearchTool[]> => {
    const response = await fetch(
      `https://searchtoolhttps-l7o6tldk5a-uc.a.run.app/?q=${searchParam}&page=${pageParam}`
    );
    return response.json();
  };

  const { data, ...rest } = useQuery<ISearchTool[], Error>(
    ['searchTools', searchParam],
    fetchTools
  );

  const tools = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return data
      .map(
        (tool) =>
          ({
            ...tool,
            category: { id: tool.category?._path?.segments?.[1] ?? '' },
            status: { id: tool.status?._path?.segments?.[1] ?? '' }
          } as ITool)
      ).filter(
        (tool) =>
          tool.category.id?.length > 0 &&
          tool.status.id !== TOOL_STATUS.UNPUBLISHED &&
          tool.status.id !== TOOL_STATUS.SCREENSHOT_ERROR
      );
  }, [data]);

  return {
    data: tools,
    ...rest
  };
};

export default useSearchTools;
