import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, where, DocumentReference } from 'firebase/firestore';

import { ITool } from './useGetTool';
import { db } from '../config/firebase';

const getTools = async (category?: DocumentReference | string) => {
  const whereStatement =
    typeof category === 'string'
      ? where('category_name', '==', category)
      : where('category', '==', category);

  const toolsQuery = query(collection(db, 'tool'), whereStatement);

  const toolSnapshot = await getDocs(toolsQuery);

  return toolSnapshot.docs.map((doc) => ({ ...(doc.data() as ITool), id: doc.id }));
};

export const useGetAllToolsByCategory = (
  category?: DocumentReference | string,
  toolId?: string
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['tool', category, toolId],
    queryFn: () => getTools(category),
    enabled: !!category
  });

  return {
    data: data ?? [],
    ...rest
  };
};
