import { setLoadingStart, setFavorites } from './toolsSlice';
import { ITool } from '../../../api/hooks/useGetTool';
import { AppThunk } from '../../root/store';

export const setFavoriteTools = (): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const favorites = JSON.parse(localStorage.getItem('favorites') ?? '[]') as ITool[];

  dispatch(setFavorites(favorites));
};

export const addToolToFavorites =
  (tool: ITool): AppThunk =>
    async (dispatch, getState) => {
      const updatedFavorites = [...getState().tools.favorites, tool];

      dispatch(setFavorites(updatedFavorites));

      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };

export const removeToolFromFavorites =
  (toolId: ITool['id']): AppThunk =>
    async (dispatch, getState) => {
      const updatedFavorites = getState().tools.favorites.filter(
        (favoriteTool) => favoriteTool.id !== toolId
      );

      dispatch(setFavorites(updatedFavorites));

      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };
