import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../libs/router/config/routes';
import { LinkBox } from '../styled/LinkBox';

interface NavigationTabProps {
  route: ROUTES;
  selected: boolean;
  name: string;
}

export const NavigationTab = ({ route, selected, name }: NavigationTabProps) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <LinkBox
      to={route}
      sx={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: 2
      }}
    >
      <Box sx={{ width: '100%', height: '3px' }} />

      <Typography
        fontSize={14}
        fontWeight={theme.typography.fontWeightBold}
        color={selected ? theme.palette.primary.main : theme.palette.customColor.gray.main}
        letterSpacing={-0.28}
        noWrap
      >
        {name}
      </Typography>

      <Box
        sx={{
          width: '100%',
          height: '3px',
          backgroundColor:
            selected && !location.pathname.includes(`${ROUTES.TOOLS_PAGE}/`)
              ? theme.palette.primary.main
              : '#ffffff',
          borderRadius: '1px'
        }}
      />
    </LinkBox>
  );
};
