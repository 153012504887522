import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, where, documentId } from 'firebase/firestore';

import { ICategory } from './useGetCategoryById';
import { db } from '../config/firebase';

const getCategories = async () => {
  const highlightedCategoriesQuery = query(collection(db, 'highlighted_categories'));
  const highlightedCategoriesSnapshot = await getDocs(highlightedCategoriesQuery);

  const categoriesIds = highlightedCategoriesSnapshot.docs.map((doc) => doc.data().category.id);

  const categoriesQuery = query(
    collection(db, 'categories'),
    where(documentId(), 'in', categoriesIds)
  );

  const categoriesSnapshot = await getDocs(categoriesQuery);

  return categoriesSnapshot.docs.map((doc) => ({
    ...(doc.data() as ICategory),
    id: doc.id
  }));
};

export const useGetHighlightedCategories = () => {
  return useQuery({
    queryKey: ['highlightedCategories'],
    queryFn: getCategories
  });
};
