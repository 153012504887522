import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  FacebookAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider
} from 'firebase/auth';

import { loginFailure, setLoadingStart, loginSuccess, logoutSuccess } from './authSlice';
import { AppThunk } from '../../root/store';

export const loginWithGoogle = (onSuccess?: () => void): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const provider = new GoogleAuthProvider();

  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      dispatch(loginSuccess(result.user));
      onSuccess?.();
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    });
};

export const loginWithFacebook = (): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const provider = new FacebookAuthProvider();

  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      dispatch(loginSuccess(result.user));
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    });
};

export const loginWithTwitter = (): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const provider = new TwitterAuthProvider();

  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      dispatch(loginSuccess(result.user));
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    });
};

export const loginWithGithub = (): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const provider = new GithubAuthProvider();

  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      dispatch(loginSuccess(result.user));
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    });
};

export const logoutUser = (): AppThunk => async (dispatch) => {
  dispatch(setLoadingStart());

  const auth = getAuth();

  await auth.signOut();
  dispatch(logoutSuccess());
};
