import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ROUTES } from './config/routes';
import AiNewsPage from '../../pages/ai-news-page/AiNewsPage';
import CategoriesPage from '../../pages/categories-page/CategoriesPage';
import CategoryPage from '../../pages/category-page/CategoryPage';
import FaqPage from '../../pages/faq-page/FaqPage';
import FavoritesPage from '../../pages/favorites-page/FavoritesPage';
import LandingPage from '../../pages/landing-page/LandingPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import RecommendedToolsPage from '../../pages/recommended-tools-page/RecommendedToolsPage';
import SearchPage from '../../pages/search-page/SearchPage';
import SubmitToolPage from '../../pages/submit-tool-page/SubmitToolPage';
import ToolPage from '../../pages/tool-page/ToolPage';
import { useGetRedirectLinks } from '../api/hooks/useGetRedirectLinks';

export default function RouterWrapper() {
  const { data: redirectLinks } = useGetRedirectLinks();

  useEffect(() => {
    if (redirectLinks?.length) {
      const foundRedirect = redirectLinks.find(
        (redirect) => redirect.from === window.location.href
      );

      if (foundRedirect) {
        window.location.replace(foundRedirect.to);
      }
    }
  }, [redirectLinks]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
        <Route path={ROUTES.TOOLS_PAGE}>
          <Route path=':categoryId' element={<CategoryPage />} />
          <Route path=':categoryId/:toolId' element={<ToolPage />} />
        </Route>
        <Route path={ROUTES.RECOMMENDED_TOOLS_PAGE} element={<RecommendedToolsPage />}/>
        <Route path={ROUTES.CATEGORIES_PAGE}>
          <Route path={ROUTES.CATEGORIES_PAGE} element={<CategoriesPage />}/>
          <Route path=':categoryId' element={<CategoryPage />} />
        </Route>
        <Route path={ROUTES.FAQ_PAGE} element={<FaqPage />} />
        <Route path={ROUTES.SEARCH_PAGE} element={<SearchPage />} />
        <Route path={ROUTES.SUBMIT_TOOL_PAGE} element={<SubmitToolPage />} />
        <Route path={ROUTES.AI_NEWS_PAGE} element={<AiNewsPage />} />
        <Route path={ROUTES.FAVORITES_PAGE} element={<FavoritesPage />} />
        <Route path={ROUTES.NOT_FOUND_PAGE} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
