import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import { FlexCol } from '../../../components/layout/FlexCol';
import GridItem from '../../../components/layout/GridItem';
import { IUsecase } from '../../../libs/api/hooks/useGetToolUsecases';

interface ToolUseCasesProps {
  useCases: IUsecase[];
  toolName: string;
}

const ToolUseCases = ({ useCases }: ToolUseCasesProps) => {
  const theme = useTheme();

  return (
    <Grid container spacing={4} sx={{ marginTop: 4 }}>
      {useCases.map((usecase) =>
        <GridItem sm={12} md={6} key={usecase.id}>
          <FlexCol>
            <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.00)', border: 1, borderColor: theme.palette.customColor.border.light }}>
              <CardContent>
                <Typography fontSize={28} marginBottom={2} lineHeight={1.2} letterSpacing={-0.56}>
                  {usecase.name}
                </Typography>

                <Typography
                  fontSize={16}
                  lineHeight={1.5}
                  letterSpacing={-0.14}
                  color={theme.palette.customColor.gray.dark}
                  whiteSpace='pre-line'
                >
                  {usecase.description}
                </Typography>
              </CardContent>
            </Card>
          </FlexCol>
        </GridItem>
      )}
    </Grid>
  );
};

export default ToolUseCases;
