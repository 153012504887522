import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import { SettingsIcon } from '../../../assets/icons';
import { FlexCol } from '../../../components/layout/FlexCol';
import GridItem from '../../../components/layout/GridItem';
import { IFeature } from '../../../libs/api/hooks/useGetToolFeatures';

interface ToolFeaturesProps {
  features: IFeature[];
}

const ToolFeatures = ({ features }: ToolFeaturesProps) => {
  const theme = useTheme();

  return (
    <Grid container spacing={4} sx={{ marginTop: 2 }}>
      {features.map((card) =>
        <GridItem xs={12} sm={6} md={4} key={card.id}>
          <FlexCol>
            <Card
              sx={{
                boxShadow: '0 0 0px rgba(0, 0, 0, 0.00)',
                backgroundColor: '#F7F7F7'
              }}
            >
              <CardContent>
                <SettingsIcon
                  style={{
                    height: 20,
                    width: 20,
                    color: theme.palette.primary.main
                  }}
                />

                <Typography
                  fontSize={16}
                  fontWeight={theme.typography.fontWeightBold}
                  lineHeight={1.5}
                  marginTop={2}
                >
                  {card.name}
                </Typography>

                <Typography
                  fontSize={16}
                  fontWeight={theme.typography.fontWeightBold}
                  lineHeight={1.5}
                  color={theme.palette.customColor.gray.light}
                  whiteSpace='pre-line'
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </FlexCol>
        </GridItem>
      )}
    </Grid>
  );
};

export default ToolFeatures;
