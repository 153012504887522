import { Box, styled, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import ReviewStars from './ReviewStars';
import { FlexCol } from '../../../components/layout/FlexCol';
import { FlexRow } from '../../../components/layout/FlexRow';
import GridContainer from '../../../components/layout/GridContainer';
import GridItem from '../../../components/layout/GridItem';
import { IToolReview } from '../../../libs/api/hooks/useGetToolReviews';

// enum LIKED_STATE {
//   LIKED = 'liked',
//   DISLIKED = 'disliked'
// }

interface ToolReviewProps {
  review: IToolReview;
  toolId: string;
}

const ToolReview = ({ review, toolId }: ToolReviewProps) => {
  const theme = useTheme();

  // const [likeState, setLikeState] = useState<LIKED_STATE | null>(null);

  // const setLikedReview = useCallback(() => {
  //   setLikeState(likeState === LIKED_STATE.LIKED ? null : LIKED_STATE.LIKED);
  // }, [likeState]);
  //
  // const setDislikedReview = useCallback(() => {
  //   setLikeState(likeState === LIKED_STATE.DISLIKED ? null : LIKED_STATE.DISLIKED);
  // }, [likeState]);

  const datePublished = useMemo(() => {
    const date = review.date.toDate();
    const formattedDate = date
      .toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
      .replace(',', '');

    const [month, day, year] = formattedDate.split(' ');
    return `${day} ${month} ${year}`;
  }, [review.date]);

  return (
    <GridContainer
      removeSpacers
      key={review.id}
      itemScope
      itemType='http://schema.org/Review'
      sx={{ backgroundColor: '#F7F7F7', borderRadius: 1, padding: 8 }}
      itemProp='itemReviewed'
      itemID={toolId}
    >
      <GridItem xs={12}>
        <FlexRow sx={{ alignItems: 'flex-start' }} gap={4}>
          <InitialsContainer
            itemProp='author'
            itemScope
            itemType='http://schema.org/Person'
            itemID={review.user}
          >
            <Typography
              fontSize={24}
              fontWeight={theme.typography.fontWeightBold}
              textTransform='uppercase'
              color={theme.palette.primary.main}
            >
              {review.user.slice(0, 2)}
            </Typography>
          </InitialsContainer>

          <FlexCol sx={{ flex: 1 }}>
            <FlexRow sx={{ justifyContent: 'space-between' }}>
              <Typography
                fontSize={16}
                lineHeight={1.5}
                fontWeight={theme.typography.fontWeightBold}
                itemProp='name'
              >
                {review.user}
              </Typography>

              <FlexCol sx={{ alignItems: 'flex-end' }}>
                <Typography fontSize={14} lineHeight={1.5} marginBottom={2}>
                  {datePublished}
                </Typography>

                <ReviewStars rating={review.rating} hideRatingValue />

                <meta
                  property='og:article:published_time'
                  itemProp='datePublished'
                  content={datePublished}
                />
              </FlexCol>
            </FlexRow>

            <Typography
              fontSize={16}
              lineHeight={1.5}
              marginTop={1}
              color={theme.palette.customColor.gray.dark}
              component='p'
              itemProp='reviewBody'
              whiteSpace='pre-line'
            >
              {review.text}
            </Typography>

            <Box itemProp='reviewRating' itemScope itemType='http://schema.org/Rating'>
              <meta
                property='og:rating'
                itemProp='ratingValue'
                content={review.rating.toString()}
              />
            </Box>
          </FlexCol>
        </FlexRow>
      </GridItem>
    </GridContainer>
  );
};

export default ToolReview;

const InitialsContainer = styled(Box)`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  align-items: center;
  justify-content: center;
  background-color: #3f5fd620;
`;
