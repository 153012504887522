import { where } from 'firebase/firestore';

export enum TOOLS_FILTER_KEYS {
  ALL = 'all',
  VERIFIED = 'company',
  RECOMMENDED = 'recommended'
}

export const TOOLS_FILTERS = {
  [TOOLS_FILTER_KEYS.ALL]: {
    value: TOOLS_FILTER_KEYS.ALL,
    name: 'all'
  },
  [TOOLS_FILTER_KEYS.VERIFIED]: {
    value: TOOLS_FILTER_KEYS.VERIFIED,
    name: 'verified'
  },
  [TOOLS_FILTER_KEYS.RECOMMENDED]: {
    value: TOOLS_FILTER_KEYS.RECOMMENDED,
    name: 'recommended'
  }
};

export const getToolsFilterBy = (filterBy?: TOOLS_FILTER_KEYS) => {
  if (!filterBy || filterBy === TOOLS_FILTER_KEYS.ALL) {
    return [];
  }

  if (filterBy === TOOLS_FILTER_KEYS.VERIFIED) {
    return [where(filterBy, '!=', null)];
  }

  if (filterBy === TOOLS_FILTER_KEYS.RECOMMENDED) {
    return [where(filterBy, '==', true)];
  }

  return [];
};
