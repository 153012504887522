const en = {
  'all-tools': 'All tools',
  categories: 'Categories',
  'recommended-tools': 'Recommended Tools',
  'ai-news': 'AI News',
  faq: 'FAQ',
  login: 'Login',
  'submit-your-tool': 'Submit your tool',
  'hello-human': 'Hello, human!',
  'early-to-ai-playground': 'You’re early to the AI Tools playground',
  'guaranteed-match':
    'We are guaranteed to match you with the best AI tool for the job you have in mind.',
  'search-tool': 'Type a category, field, industry or a specific AI tool',
  search: 'Search',
  'ai-opportunity': 'Artificial Intelligence technology is the new opportunity creator for all businesses, regardless of their size. It supercharges your productivity to a level we’ve never encountered before.',
  'recommended': 'Recommended',
  'frequently-asked-questions': 'Frequently Asked Questions',
  'faq-page-description': 'Everything you need to know about our platform, AI Technology and more. All your questions answered here. Can’t find the answer you are looking for?',
  'contact-us': 'Contact us',
  'enter-your-email-address': 'Enter your email address',
  'useful-links': 'Useful links',
  'subscribe-to-news': 'Subscribe to News',
  'view-all-categories': 'View all categories',
  'what-is-yat': 'What is YourAITool?',
  'footer-description': 'Our algorithm creates a completely personalized experience to match you with the best AI tool that works for you. Experiment with workflow automation, content writers, image generators or simply play with AI toys.',
  blog: 'Blog',
  all: 'All',
  verified: 'Verified',
  'recommended-tool': 'Recommended tool',
  'about-tool': 'About\n{{toolName}}',
  'verified-veridion': 'Verified by Veridion.',
  'best-usecases-for-tool': 'Best scenarios and use cases for {{toolName}}',
  'favorite': 'Favorite',
  'visit': 'Visit',
  'hmm': 'Hmm...',
  'no-tool-matching': 'It seems like there is no tool matching your search criteria. Let\'s try another search',
  'welcome': 'Welcome',
  'login-message': 'We are building a directory of the strongest AIs on the market today.',
  'login-with': 'Log in with',
  'no-categories-found': 'No categories found',
  ////////////////////////////
  ////////////////////////////
  ////////////////////////////
  ////////////////////////////
  ////////////////////////////

  home: 'Home',
  'new-tools': 'New tools',
  'chrome-extensions': 'Chrome Extensions',
  'show-more-ai-tools': 'Show more Ai tools',
  'bring-ai-wherever': 'Bring AI wherever you want',
  'capable-connecting-dots': 'They are capable of connecting the dots and solving issues.',
  'accessible-versatile': 'They are accessible, approachable, and insanely versatile.',
  'all-rights-reserved': 'All rights reserved',
  copyright: 'Copyright {{year}} YourAiTool',
  'join-community': 'Join our community',
  'community-description':
    'We are building a directory of the strongest AIs on the market today. Our aggregator is an expert in matching users to the AIs they genuinely need.',
  'back-to-list': 'Back to list',
  veridion: 'Veridion',
  score: 'Score',
  rating: 'Rating',
  cost: 'Cost',
  'open-tool': 'Open tool',
  'provider-details': 'Provider details',
  'my-account': 'My account',
  'more-from-category': 'More from this category',
  alternatives: 'Alternatives',
  reviews: 'Reviews',
  profile: 'Profile',
  favorites: 'Favorites',
  logout: 'Logout',
  oops: 'Oops!',
  'page-not-found': 'Page not found',
  company: 'Company',
  name: 'Name',
  country: 'Country',
  'employee-count': 'Employee count',
  'estimated-revenue': 'Estimated revenue',
  location: 'Location',
  'main-business-category': 'Main business category',
  email: 'Email',
  phone: 'Phone',
  'year-founded': 'Year founded',
  type: 'Type',
  'you-might-like': 'You might like',
  'no-favorites': 'You do not have any favorite tools',
  category: 'Category',
  'see-more': 'See more',
  'submit-tool-title': 'Send your tool to us',
  'submit-tool-description':
    'We will do our best in order to make your tool visible many users around the world',
  'tool-name': 'Tool name',
  website: 'Website',
  'short-tool-description': 'Short tool description',
  'complete-tool-description': 'Complete tool description',
  pricing: 'Pricing',
  features: 'Features',
  'starting-price': 'Starting price',
  'is-this-your-tool': 'Is this your tool?',
  'email-invalid': 'Invalid email',
  'is-required': ' is required',
  'show-more-categories': 'Show more categories',
  back: 'Back',
  'to-main-page': 'To main page',
  'tool-submitted-successfully': 'Tool submitted successfully',
  'visit-website': 'Visit website',
  description: 'Description',
  'company-name': 'Company name',
  'add-to-favorites': 'Add to favorites',
  'browse-categories':
    'Browse all our categories of AI websites for a comprehensive overview. From audio editing, and copywriting to development, we are covering every domain that you are looking for.',
  'search-categories': 'Search categories by name or field',
  overview: 'Overview',
  usecases: 'Use cases',
  resources: 'Resources',
  'follow-us': 'Follow Us',
  videos: 'Videos',
  'sort-by': 'Sort by',
  'filter-by': 'Filter by'
};

export default en;
