import React from 'react';
import { Helmet } from 'react-helmet';

import { getLandingPageLink } from '../../../libs/router/config/routes';

const LandingPageMeta = ({ toolsCount }: { toolsCount?: number }) => {
  return (
    <Helmet>
      <title>YourAITool - The Largest Collection of the Best AI Tools in the Market</title>
      <meta
        property='og:title'
        name='title'
        content='YourAITool - The Largest Collection of the Best AI Tools in the Market'
      />
      <meta
        property='og:description'
        name='description'
        content={
          'Discover the largest collection of the best AI tools in the market with YourAITool.' +
          ` ${toolsCount}+ tools for all your business needs like automation, art and image ` +
          'generating, content writing and more.'
        }
      />
      <meta property='og:url' content='https://youraitool.com'/>

      <link rel='canonical' href={getLandingPageLink(true)} />
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://www.youraitool.com/',
          name: 'YourAITool - The Largest Collection of the Best AI Tools in the Market',
          description:
            'Discover the largest collection of the best AI tools in the market with YourAITool.' +
            `${toolsCount}+ tools for all your business needs like automation, art and image ` +
            'generating, content writing and more.'
        })}
      </script>
    </Helmet>
  );
};

export default LandingPageMeta;
