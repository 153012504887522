import { Box, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { ICategory } from '../../libs/api/hooks/useGetCategoryById';
import { getCategoryPageLink } from '../../libs/router/config/routes';
import { FlexCol } from '../layout/FlexCol';
import { LinkBox } from '../styled/LinkBox';

interface CategoryCardProps {
  category: ICategory;
}

// const colorPairs = [
//   // Blue origin
//   ['#007bff', '#3a7bd5'], // Bright blue to Medium blue
//   ['#007bff', '#5d26c1'], // Bright blue to Deep purple
//   ['#007bff', '#0d47a1'], // Bright blue to Dark blue
//   ['#1565c0', '#0d47a1'], // Moderate blue to Dark blue
//   ['#2979ff', '#448aff'], // Bright blue to Light blue
//   ['#1e88e5', '#039be5'], // Strong blue to Bright blue
//
//   // Green origin
//   ['#1faa59', '#3a7bd5'], // Dark green to Medium blue
//   ['#1faa59', '#11998e'], // Dark green to Turquoise
//   ['#006400', '#006400'], // Dark green to Dark green
//   ['#006400', '#1b5e20'], // Dark green to Moderate green
//   ['#00c853', '#76ff03'], // Strong green to Light green
//   ['#64dd17', '#00e676'], // Bright green to Light green
//
//   // Orange origin
//   ['#f09819', '#ff512f'], // Bright orange to Dark orange
//   ['#f09819', '#edde5d'], // Bright orange to Yellowish
//   ['#ffa500', '#ff4500'], // Orange to Dark orange
//   ['#ffa500', '#daa520'], // Orange to Goldenrod
//   ['#fb8c00', '#ffab00'], // Dark orange to Bright orange
//   ['#ff9100', '#ff6d00'], // Bright orange to Strong orange
//
//   // Violet origin
//   ['#3a7bd5', '#5d26c1'], // Medium blue to Deep purple
//   ['#5d26c1', '#833ab4'], // Deep purple to Dark violet
//   ['#5d26c1', '#4a148c'], // Deep purple to Dark purple
//   ['#6a1b9a', '#4a148c'], // Moderate purple to Dark purple
//   ['#7c4dff', '#651fff'], // Bright violet to Dark violet
//   ['#6200ea', '#304ffe'], // Deep violet to Bright blue
//
//   // Red origin
//   ['#ff0000', '#b71c1c'], // Red to Dark red
//   ['#e53935', '#b71c1c'], // Moderate red to Dark red
//   ['#f44336', '#b71c1c'], // Light red to Dark red
//   ['#d50000', '#c51162'], // Strong red to Deep pink
//   ['#ff1744', '#f50057'], // Bright red to Strong pink
//
//   // Brown origin
//   ['#6d4c41', '#3e2723'], // Brown to Dark brown
//   ['#4e342e', '#3e2723'], // Dark brown to Darker brown
//   ['#795548', '#3e2723'], // Light brown to Dark brown
//   ['#6d4c41', '#bf360c'], // Brown to Dark orange
//   ['#4e342e', '#3e2723'] // Dark brown to Dark brown
// ];

const CategoryCard = ({ category }: CategoryCardProps) => {
  const linkProps = useMemo(() => {
    if (category.id === 'automation') {
      return {
        to: getCategoryPageLink(category.id)
      };
    } else {
      return {
        to: getCategoryPageLink(category.id, true),
        rel: 'canonical'
      };
    }
  }, [category]);

  return (
    <CategoryContainer>
      <LinkBox {...linkProps}>
        <FlexCol>
          <Typography sx={{ fontSize: 16, fontWeight: '500' }}>{category.category}</Typography>
          <Typography sx={{ fontSize: 13, fontWeight: '400', color: '#888888' }}>
            {category.tools}
          </Typography>
        </FlexCol>
      </LinkBox>
    </CategoryContainer>
  );
};

export default CategoryCard;

const CategoryContainer = styled(Box)`
  border-radius: 10px;
  border: 1px solid;
  border-color: #e5e5e5;
  background-color: #ffffff;
  padding: 24px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  transition: transform 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  }
`;
