import { styled, IconButton, BoxProps } from '@mui/material';
import React, { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { HeartIcon } from '../../assets/icons';
import { useToggle } from '../../libs/hooks/utils/use-toggle';
import { getIsUserLoggedIn } from '../../libs/redux/slices/auth/authSelectors';
import LoginModal from '../modals/LoginModal';

interface FavoriteButtonProps {
  isFavorite?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  sx?: BoxProps['sx'];
  size?: number;
}

const FavoriteButton = ({ isFavorite, onClick, sx = {}, size = 24 }: FavoriteButtonProps) => {
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const [loginModalOpen, toggleLoginModalOpen] = useToggle(false);

  const onClickFavorite = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isUserLoggedIn) {
      toggleLoginModalOpen();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  }, [isUserLoggedIn, onClick, toggleLoginModalOpen]);

  return (
    <FavoriteStyledButton onClick={onClickFavorite} sx={sx}>
      <HeartIcon
        style={
          isFavorite
            ? { color: '#FF4444', fill: '#FF4444', width: `${size}px`, height: `${size}px` }
            : { width: `${size}px`, height: `${size}px` }
        }
      />

      <LoginModal open={loginModalOpen} handleClose={toggleLoginModalOpen} />
    </FavoriteStyledButton>
  );
};

export default FavoriteButton;

const FavoriteStyledButton = styled(IconButton)`
  background-color: #ffffff;
  padding: 1;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;

  &.MuiIconButton-root:hover {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;
