import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Container } from '@mui/material';
import { getAuth } from 'firebase/auth';
import React from 'react';

import { JoinCommunityModal } from './components/modals/JoinCommunityModal';
import { useActionDispatch } from './libs/redux/root/store';
import { loginSuccess, logoutSuccess } from './libs/redux/slices/auth/authSlice';
import { setFavoriteTools } from './libs/redux/slices/tools/toolsActions';
import RouterWrapper from './libs/router/RouterWrapper';

const speedy = navigator.userAgent.toLowerCase().indexOf('prerender') === -1;

const emotionCache = createCache({
  key: 'emotion-cache',
  speedy: speedy
});

function App() {
  const auth = getAuth();

  const dispatch = useActionDispatch();

  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch(loginSuccess(user));
      dispatch(setFavoriteTools());
    } else {
      dispatch(logoutSuccess());
    }
  });

  return (
    <CacheProvider value={emotionCache}>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: '#FFFFFF', height: '100vh' }}
        disableGutters
      >
        <JoinCommunityModal/>
        <RouterWrapper />
      </Container>
    </CacheProvider>
  );
}

export default App;
