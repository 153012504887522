import React from 'react';
import { Helmet } from 'react-helmet';

import { getCategoriesPageLink } from '../../../libs/router/config/routes';

const CategoriesPageMeta = () => {
  return (
    <Helmet>
      <title>YourAITool - All Categories of AI Websites</title>
      <meta
        name='title'
        property='og:title'
        content='YourAITool - All Categories of AI Websites'
      />
      <meta
        property='og:description'
        name='description'
        content='Browse all our categories of AI websites for a comprehensive overview. From audio editing, and copywriting to development, we are covering every domain that you are looking for.'
      />
      <link rel='canonical' href={getCategoriesPageLink(true)} />
    </Helmet>

  );
};

export default CategoriesPageMeta;
