import { useMutation } from '@tanstack/react-query';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

import { db } from '../config/firebase';

export const useSetUserSearchQuery = (onSuccess?: () => void) => {
  const saveSearchQuery = async (searchQuery: string) => {
    await addDoc(collection(db, 'user_searches'), {
      searchQuery: searchQuery,
      date: serverTimestamp()
    });
  };

  return useMutation(saveSearchQuery, {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    }
  });
};
