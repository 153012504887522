import { ContainerProps, Container } from '@mui/material';
import React from 'react';

const ContentContainer = ({ children, ...rest }: ContainerProps) => {
  return (
    <Container maxWidth='xl' disableGutters {...rest}>
      {children}
    </Container>
  );
};

export default ContentContainer;
