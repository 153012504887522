import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CategoryPageMeta from './components/CategoryPageMeta';
import NotFound from '../../components/data-display/NotFound';
import ToolList from '../../components/data-display/ToolList';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import { useGetAllToolsByCategory } from '../../libs/api/hooks/useGetAllToolsByCategory';
import { useGetCategoryById } from '../../libs/api/hooks/useGetCategoryById';
import { getFavoriteTools } from '../../libs/redux/slices/tools/toolsSelectors';
import { getLandingPageLink } from '../../libs/router/config/routes';

const CategoryPage = () => {
  const { categoryId } = useParams();
  const theme = useTheme();

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(
    () => favoriteTools.map((favoriteTool) => favoriteTool.id),
    [favoriteTools]
  );

  const { data: categoryData, isLoading: isLoadingCategory } = useGetCategoryById(categoryId);

  const { data: tools, isLoading, error } = useGetAllToolsByCategory(categoryData?.category);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categoryId]);

  return (
    <PageContainer>
      <CategoryPageMeta
        categoryId={categoryId}
        categoryName={categoryData?.category}
        categoryDescription={categoryData?.description}
        toolsCount={categoryData?.tools}
      />

      {error || !categoryData && !isLoadingCategory ?
        <NotFound />
        :
        <GridContainer sx={{ paddingY: 12 }} itemScope itemType='https://schema.org/ItemList'>
          <GridItem xs={12}>
            <Breadcrumbs
              aria-label='breadcrumb'
              sx={{ marginBottom: 8, marginTop: 4 }}
              separator={<NavigateNextIcon fontSize='small' />}
            >
              <Link
                underline='hover'
                color='inherit'
                href={getLandingPageLink()}
                fontSize={16}
                fontWeight='500'
              >
                Tools
              </Link>

              <Typography fontSize={16} fontWeight='500' color='#000000'>
                {categoryData?.category}
              </Typography>
            </Breadcrumbs>
          </GridItem>

          <GridItem xs={12}>
            <FlexCol gap={4}>
              <Typography
                maxWidth='sm'
                fontSize={28}
                letterSpacing={-0.56}
                fontWeight={theme.typography.fontWeightBold}
              >
                {categoryData?.category}
              </Typography>

              <Typography display='none' variant='h1'>
                {`AI ${categoryData?.category} tools`}
              </Typography>

              <Typography
                maxWidth='md'
                fontSize={16}
                lineHeight={1.5}
                variant='h2'
                color={theme.palette.customColor.gray.dark}
                fontWeight={theme.typography.fontWeightLight}
                zIndex={1}
                letterSpacing={-0.14}
                marginBottom={12}
              >
                {categoryData?.description
                  ? categoryData.description
                  : `Discover the top picks for ${categoryData?.category} tools ` +
                    'based on Artificial Intelligence that can help you work smarter.'}
              </Typography>
            </FlexCol>
          </GridItem>

          <ToolList
            tools={tools}
            removeCanonical={categoryId === 'automation'}
            favoriteToolsIds={favoriteToolsIds}
            isLoading={isLoading}
          />
        </GridContainer>
      }
    </PageContainer>
  );
};

export default CategoryPage;
