import { combineReducers } from 'redux';

import { AuthReducer, AuthState } from '../slices/auth/authSlice';
import { ToolsReducer, ToolsState } from '../slices/tools/toolsSlice';

export type RootState = {
  auth: AuthState;
  tools: ToolsState;
};

const rootReducer = combineReducers<RootState>({
  auth: AuthReducer,
  tools: ToolsReducer
});

export default rootReducer;
