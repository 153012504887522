import { yupResolver } from '@hookform/resolvers/yup';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  styled,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { features } from './data/features';
import { pricing } from './data/pricing';
import { FlexCol } from '../../components/layout/FlexCol';
import PageContainer from '../../components/layout/PageContainer';
import { useGetCategories } from '../../libs/api/hooks/useGetCategories';
import { ISubmittedTool, useSetSubmitTool } from '../../libs/api/hooks/useSetSubmitTool';

const initialValues = {
  email: '',
  toolName: '',
  website: '',
  shortDescription: '',
  completeDescription: '',
  categories: [] as string[],
  price: '',
  features: [] as string[],
  startingPrice: 0,
  owner: false
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  toolName: yup.string().required('Tool name is required'),
  website: yup.string().url('Invalid Website URL').required('Website is required'),
  shortDescription: yup.string().required('Short description is required'),
  completeDescription: yup.string().required('Complete descriptions is required'),
  categories: yup.array().min(1, 'Select at least one category').required('Category is required'),
  price: yup.string().typeError('Invalid price').required('Price is required'),
  owner: yup.boolean().required('Owner is required'),
  features: yup.array().min(1, 'Select at least one feature').required('Features are required'),
  startingPrice: yup
    .number()
    .min(0, 'Starting price must be equal or greater than 0')
    .typeError('Invalid price')
    .required('Starting price is required')
});

const SubmitToolPage = () => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const { data: categories } = useGetCategories();
  const { mutate: submitTool, isLoading, isSuccess } = useSetSubmitTool(reset);

  const onSubmit = useCallback(
    (data: ISubmittedTool) => {
      submitTool(data);
    },
    [submitTool]
  );

  return (
    <PageContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSuccess}
        autoHideDuration={3000}
      >
        <Alert severity='success'>{t('tool-submitted-successfully')}</Alert>
      </Snackbar>

      <Container maxWidth='md'>
        <Box sx={{ marginY: '64px' }}>
          <Typography fontSize={32} textAlign='center'>
            {t('submit-tool-title')}
          </Typography>

          <Typography fontSize={16} textAlign='center' marginTop={8}>
            {t('submit-tool-description')}
          </Typography>
        </Box>

        <FlexCol sx={{ paddingBottom: '64px' }}>
          <FieldTitle>{t('email')}</FieldTitle>

          <Controller
            name='email'
            control={control}
            defaultValue=''
            render={({ field }) =>
              <TextField
                {...field}
                variant='outlined'
                placeholder={t('email') ?? ''}
              />
            }
          />

          <FieldTitle>{t('tool-name')}</FieldTitle>

          <Controller
            name='toolName'
            control={control}
            defaultValue=''
            render={({ field }) =>
              <TextField
                {...field}
                variant='outlined'
                placeholder={t('tool-name') ?? ''}
              />
            }
          />

          <FieldTitle>{t('website')}</FieldTitle>

          <Controller
            name='website'
            control={control}
            defaultValue=''
            render={({ field }) =>
              <TextField {...field} placeholder={t('website') ?? ''} variant='outlined' />
            }
          />

          <FieldTitle>{t('short-tool-description')}</FieldTitle>

          <Controller
            name='shortDescription'
            control={control}
            defaultValue=''
            render={({ field }) =>
              <TextField
                {...field}
                variant='outlined'
                placeholder={t('short-tool-description') ?? ''}
                multiline
                rows={4}
              />
            }
          />

          <FieldTitle>{t('complete-tool-description')}</FieldTitle>

          <Controller
            name='completeDescription'
            control={control}
            defaultValue=''
            render={({ field }) =>
              <TextField
                {...field}
                variant='outlined'
                placeholder={t('complete-tool-description') ?? ''}
                multiline
                rows={4}
              />
            }
          />

          <FieldTitle>{t('categories')}</FieldTitle>

          <Controller
            name='categories'
            control={control}
            defaultValue={initialValues.categories}
            render={({ field }) =>
              <ListContainer>
                <FlexCol>
                  {categories.map((category) =>
                    <FormControlLabel
                      key={category.id}
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value.includes(category.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              field.onChange([...field.value, category.id]);
                            } else {
                              field.onChange(field.value.filter((item) => item !== category.id));
                            }
                          }}
                        />
                      }
                      label={category.category}
                    />
                  )}
                </FlexCol>
              </ListContainer>
            }
          />

          <FieldTitle>{t('pricing')}</FieldTitle>

          <Controller
            name='price'
            control={control}
            render={({ field }) =>
              <ListContainer>
                <FlexCol>
                  <RadioGroup {...field}>
                    {pricing.map((option) =>
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    )}
                  </RadioGroup>
                </FlexCol>
              </ListContainer>
            }
          />

          <FieldTitle>{t('features')}</FieldTitle>

          <Controller
            name='features'
            control={control}
            defaultValue={initialValues.features}
            render={({ field }) =>
              <ListContainer>
                <FlexCol>
                  {features.map((feature) =>
                    <FormControlLabel
                      key={feature}
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value.includes(feature)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              field.onChange([...field.value, feature]);
                            } else {
                              field.onChange(field.value.filter((item) => item !== feature));
                            }
                          }}
                        />
                      }
                      label={feature}
                    />
                  )}
                </FlexCol>
              </ListContainer>
            }
          />

          <FieldTitle>{t('starting-price')}</FieldTitle>

          <Controller
            name='startingPrice'
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                variant='outlined'
                placeholder={t('starting-price') ?? ''}
                type='number'
                inputProps={{ min: 0 }}
              />
            }
          />

          <FieldTitle>{t('is-this-your-tool')}</FieldTitle>

          <Controller
            name='owner'
            control={control}
            render={({ field }) =>
              <ListContainer>
                <FlexCol>
                  <RadioGroup {...field}>
                    {['Yes', 'No'].map((option) =>
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    )}
                  </RadioGroup>
                </FlexCol>
              </ListContainer>
            }
          />


          {errors && Object.values(errors).length > 0 &&
            <Alert severity='error' sx={{ marginTop: '32px' }}>
              {Object.values(errors)[0]?.message}
            </Alert>
          }
          <Button
            type='submit'
            variant='contained'
            sx={{ padding: 4, borderRadius: '16px', marginTop: '64px' }}
            startIcon={isLoading ? null : <FormatAlignJustifyOutlinedIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? <CircularProgress size={24} color='inherit' /> : 'Submit'}
          </Button>
        </FlexCol>
      </Container>
    </PageContainer>
  );
};

export default SubmitToolPage;

const ListContainer = styled(Box)`
  overflow: auto;
  max-height: 500px;
  border-radius: 16px;
  background-color: #f8f8f8;
  padding: 16px;
`;

const FieldTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 16px;
`;
