import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query } from 'firebase/firestore';

import { db } from '../config/firebase';

export interface RedirectLink {
  id: string;
  from: string;
  to: string;
}
const getRedirectLinks = async () => {
  const redirectLinksQuery = query(collection(db, 'redirect_links'));
  const redirectLinksSnapshot = await getDocs(redirectLinksQuery);

  return redirectLinksSnapshot.docs.map((doc) => ({
    ...(doc.data() as RedirectLink),
    id: doc.id
  }));
};

export const useGetRedirectLinks = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['redirectLinks'],
    queryFn: getRedirectLinks
  });

  return {
    data: data ?? [],
    ...rest
  };
};
