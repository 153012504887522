import { useQuery } from '@tanstack/react-query';
import { collection, getCountFromServer, query } from 'firebase/firestore';

import { db } from '../config/firebase';
import { getToolsFilterBy, TOOLS_FILTER_KEYS } from '../helpers/getToolsFilterBy';

const getToolsCount = async (filterBy?: TOOLS_FILTER_KEYS) => {
  const filterToolsBy = getToolsFilterBy(filterBy);

  const toolCollection = collection(db, 'tool');
  const toolCount = await getCountFromServer(query(toolCollection, ...filterToolsBy));

  return toolCount.data()?.count;
};

export const useGetToolsCount = (filterBy?: TOOLS_FILTER_KEYS) => {
  const { data: count, ...rest } = useQuery({
    queryKey: ['toolCount', filterBy],
    queryFn: () => getToolsCount(filterBy)
  });

  return {
    data: count,
    ...rest
  };
};
