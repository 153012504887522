import { createSelector } from 'reselect';

import { RootStore } from '../../root/store';

export const getIsUserLoggedIn = createSelector(
  (state: RootStore) => !!state.auth.user?.uid,
  (isLoggedIn) => isLoggedIn
);

export const getUser = createSelector(
  (state: RootStore) => state.auth.user,
  (user) => user
);

export const getUserId = createSelector(
  (state: RootStore) => state.auth.user?.uid,
  (id) => id
);
