import { useQuery } from '@tanstack/react-query';
import {
  query,
  collection, getDocs
} from 'firebase/firestore';

import { db } from '../config/firebase';

interface IOverview {
  id: string;
  name: string;
  specs: string[];
}

const getToolOverview = async (toolId?: string) => {
  if (!toolId) {
    throw new Error('No tool id!');
  }

  const overviewQuery = query(
    collection(db, 'tool', toolId, 'overview'),
  );

  const overviewDocs = await getDocs(overviewQuery);

  if (overviewDocs?.docs) {
    return overviewDocs.docs.map(doc => ({ ...(doc.data() as IOverview), id: doc.id }));
  } else {
    return [];
  }
};

export const useGetToolOverview = (toolId?: string) =>
  useQuery({
    queryKey: ['toolOverview', toolId],
    queryFn: () => getToolOverview(toolId),
    enabled: !!toolId
  });
