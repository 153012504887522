import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FadingModal from './FadingModal';
import { CloseIcon, GoogleColoredIcon } from '../../assets/icons';
import { useActionDispatch } from '../../libs/redux/root/store';
import { loginWithGoogle } from '../../libs/redux/slices/auth/authActions';
import { FlexCol } from '../layout/FlexCol';
import { FlexRow } from '../layout/FlexRow';

interface LoginModalProps {
  open: boolean;
  handleClose: () => void;
}

const LoginModal = ({ open, handleClose }: LoginModalProps) => {
  const dispatch = useActionDispatch();

  const { t } = useTranslation();

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleGoogleLogin = useCallback(
    () => dispatch(loginWithGoogle(handleClose)),
    [dispatch, handleClose]
  );

  // const handleFacebookLogin = useCallback(() => dispatch(loginWithFacebook()), []);
  //
  // const handleTwitterLogin = useCallback(() => dispatch(loginWithTwitter()), []);
  //
  // const handleGithubLogin = useCallback(() => dispatch(loginWithGithub()), []);

  return (
    <FadingModal
      open={open}
      handleClose={handleClose}
      containerStyle={{
        width: isSmallScreen ? 'calc(100vw - 64px)' : undefined,
        padding: 0,
        maxWidth: 'sm'
      }}
    >
      <FlexCol sx={{ backgroundColor: '#000000', width: '100%' }}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 0, zIndex: 2 }}>
          <CloseIcon style={{ color: '#FFFFFF', padding: 8 }} />
        </IconButton>

        <img
          src={require('../../assets/images/login-banner.png')}
          style={{ height: 'auto', width: '100%', zIndex: 1 }}
          alt='https://youraitool.com/'
        />
      </FlexCol>

      <FlexCol sx={{ alignItems: 'center', paddingY: 4, paddingX: 8 }}>
        <Typography
          textAlign='center'
          fontSize={28}
          lineHeight={1.5}
          fontWeight={theme.typography.fontWeightBold}
        >
          {t('welcome')}
        </Typography>

        <Typography textAlign='center' fontSize={14} lineHeight={1.5}>
          {t('login-message')}
        </Typography>

        <FlexRow
          sx={{ marginTop: 4, marginBottom: 4, alignItems: 'center', width: '100%' }}
          gap={2}
        >
          <Box
            sx={{ height: 2, flex: 1, backgroundColor: theme.palette.customColor.border.light }}
          />
          <Typography
            textAlign='center'
            fontSize={14}
            whiteSpace='nowrap'
            color={theme.palette.customColor.gray.dark}
          >
            {t('login-with')}
          </Typography>

          <Box
            sx={{ height: 2, flex: 1, backgroundColor: theme.palette.customColor.border.light }}
          />
        </FlexRow>

        <LoginButton
          startIcon={<GoogleColoredIcon style={{ width: 28, height: 28 }} />}
          onClick={handleGoogleLogin}
          sx={{ color: '#EA4334' }}
        >
          Google
        </LoginButton>

        {/*<LoginButton*/}
        {/*  startIcon={<FacebookIcon sx={{ width: '28px', height: '28px' }} />}*/}
        {/*  onClick={handleFacebookLogin}*/}
        {/*>*/}
        {/*  Login with Facebook*/}
        {/*</LoginButton>*/}

        {/*<LoginButton*/}
        {/*  startIcon={<TwitterIcon sx={{ width: '28px', height: '28px' }} />}*/}
        {/*  onClick={handleTwitterLogin}*/}
        {/*>*/}
        {/*  Login with Twitter*/}
        {/*</LoginButton>*/}

        {/*<LoginButton*/}
        {/*  startIcon={<GitHubIcon sx={{ width: '28px', height: '28px' }} />}*/}
        {/*  onClick={handleGithubLogin}*/}
        {/*>*/}
        {/*  Login with Github*/}
        {/*</LoginButton>*/}
      </FlexCol>
    </FadingModal>
  );
};

export default LoginModal;

const LoginButton = styled(Button)`
  background-color: #fff;
  color: #737373;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 8px 32px;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  & .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;
