import { GridProps, Grid } from '@mui/material';
import React from 'react';

import GridItem from './GridItem';

interface GridContainerProps extends GridProps {
  removeSpacers?: boolean;
  contentSpacing?: number;
  columns?: number;
  spacingColumns?: number;
}

const GridContainer = ({
  removeSpacers,
  contentSpacing,
  children,
  columns = 12,
  spacingColumns = 1,
  ...rest
}: GridContainerProps) => {
  return (
    <Grid container spacing={0} columns={columns} {...rest}>
      {!removeSpacers && <GridItem xs={spacingColumns} />}

      <Grid item xs={removeSpacers ? columns : columns - spacingColumns * 2}>
        <Grid
          container
          columns={removeSpacers ? columns : columns - spacingColumns * 2}
          spacing={contentSpacing}
        >
          {children}
        </Grid>
      </Grid>

      {!removeSpacers && <GridItem xs={spacingColumns} />}
    </Grid>
  );
};

export default GridContainer;
