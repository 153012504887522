import { Box, Button, Card, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLinkIcon } from '../../../assets/icons';

interface ToolImageProps {
  image: string;
  url?: string;
  title: string;
  categoryName: string;
}

const ToolImage = ({ image, url, title, categoryName }: ToolImageProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ImageCard>
      <Image
        src={image}
        title={`${title} ai tool`}
        alt={`${title} ai tool ${categoryName}`}
      />

      <Overlay className='overlay'>
        {url &&
          <Button
            href={`${url}?utm_source=youraitool&utm_medium=aggregator&utm_campaign=youraitool`}
            target='_blank'
            variant='contained'
            endIcon={
              <ExternalLinkIcon
                style={{ width: 18, height: 18, color: theme.palette.primary.main }}
              />
            }
            sx={{
              backgroundColor: '#FFFFFF',
              fontSize: 16,
              textTransform: 'none',
              color: theme.palette.primary.main,
              fontWeight: '500',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
              paddingX: 8,
              paddingY: 2,
              '&:hover': {
                backgroundColor: '#FFFFFF',
                '& .MuiTouchRipple-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }
            }}
          >
            {t('visit-website')}
          </Button>
        }
      </Overlay>
    </ImageCard>

  );
};

export default ToolImage;

const ImageCard = styled(Card)(() => ({
  borderRadius: 20,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  marginTop: 4,
  '&:hover img': {
    filter: 'brightness(0.8)' // Adjust the brightness as needed
  },
  '&:hover .overlay': {
    opacity: 1
  }
}));

const Image = styled('img')({
  height: 'auto',
  width: '100%',
  objectFit: 'contain',
  borderRadius: '8px',
  transition: 'filter 0.3s ease'
});

const Overlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
