import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { List, ListItem, IconButton, Box } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';

import { ITool } from '../../libs/api/hooks/useGetTool';
import ToolCard from '../cards/ToolCard';
import { FlexRow } from '../layout/FlexRow';

interface HorizontalProductsListProps {
  tools: ITool[];
  onMiddleReached?: (() => void) | (() => Promise<void>);
}

const HorizontalToolList = ({ tools, onMiddleReached }: HorizontalProductsListProps) => {
  const [scrollData, setScrollData] = useState({
    scrollValue: 0,
    page: 0
  });

  const listRef = useRef<HTMLUListElement>(null);

  const handleScrollLeft = useCallback(() => {
    if (listRef.current) {
      const scrollAmount = listRef.current.offsetWidth;

      setScrollData({
        page: scrollData.page - 1,
        scrollValue: scrollData.scrollValue - scrollAmount
      });

      listRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    }
  }, [scrollData.page, scrollData.scrollValue]);

  const handleScrollRight = useCallback(async () => {
    if (listRef.current) {
      if (
        onMiddleReached &&
        listRef.current.scrollLeft * 2 >= listRef.current.scrollWidth - listRef.current.offsetWidth
      ) {
        onMiddleReached();
      }

      const scrollAmount = listRef.current.offsetWidth;

      setScrollData({
        page: scrollData.page + 1,
        scrollValue: scrollData.scrollValue + scrollAmount
      });

      listRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  }, [onMiddleReached, scrollData.page, scrollData.scrollValue]);

  return (
    <FlexRow sx={{ alignItems: 'center' }}>
      <IconButton disabled={scrollData.page === 0} onClick={handleScrollLeft}>
        <ArrowBackIosRoundedIcon sx={{ width: '22px', height: '22px', color: 'gray' }} />
      </IconButton>

      <Box sx={{ overflow: 'hidden', width: '100%', flex: 1 }}>
        <List
          ref={listRef}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            margin: 0,
            listStyleType: 'none',
            overflow: 'hidden'
          }}
        >
          {tools.map((item) =>
            <ListItem
              key={item.id}
              sx={{
                flexShrink: 0,
                flexBasis: '100%',
                maxWidth: '100%',
                '@media (min-width: 600px)': {
                  flexBasis: '50%',
                  maxWidth: '50%'
                },
                '@media (min-width: 900px)': {
                  flexBasis: '33.33%',
                  maxWidth: '33.33%'
                },
                '@media (min-width: 1200px)': {
                  flexBasis: '25%',
                  maxWidth: '25%'
                }
              }}
            >
              <ToolCard tool={item} />
            </ListItem>
          )}
        </List>
      </Box>

      <IconButton
        disabled={
          !listRef ||
          !!listRef?.current &&
            listRef.current.scrollLeft >= listRef.current.scrollWidth - listRef.current.offsetWidth
        }
        onClick={handleScrollRight}
      >
        <ArrowForwardIosRoundedIcon sx={{ width: '22px', height: '22px', color: 'gray' }} />
      </IconButton>
    </FlexRow>
  );
};

export default HorizontalToolList;
