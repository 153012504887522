import { Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import RecommendedToolsPageMeta from './components/RecommendedToolsPageMeta';
import ToolList from '../../components/data-display/ToolList';
import { FlexCol } from '../../components/layout/FlexCol';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';
import PageContainer from '../../components/layout/PageContainer';
import { ShowMoreButton } from '../../components/styled/ShowMoreButton';
import { TOOLS_FILTER_KEYS } from '../../libs/api/helpers/getToolsFilterBy';
import { useGetTools } from '../../libs/api/hooks/useGetTools';
import { getFavoriteTools } from '../../libs/redux/slices/tools/toolsSelectors';

const RecommendedToolsPage = () => {
  const { categoryId } = useParams();
  const theme = useTheme();

  const { t } = useTranslation();

  const favoriteTools = useSelector(getFavoriteTools);
  const favoriteToolsIds = useMemo(
    () => favoriteTools.map((favoriteTool) => favoriteTool.id),
    [favoriteTools]
  );

  const {
    data: tools,
    count,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useGetTools({
    filterBy: TOOLS_FILTER_KEYS.RECOMMENDED
  });

  const onClickShowMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categoryId]);

  return (
    <PageContainer>
      <RecommendedToolsPageMeta toolsCount={count}/>
      <GridContainer sx={{ paddingY: 12 }} itemScope itemType='https://schema.org/ItemList'>
        <GridItem xs={12}>
          <Typography
            maxWidth='sm'
            fontSize={28}
            letterSpacing={-0.56}
            fontWeight={theme.typography.fontWeightBold}
            marginBottom={4}
          >
            {t('recommended-tools')}
          </Typography>
        </GridItem>

        <ToolList tools={tools} favoriteToolsIds={favoriteToolsIds} isLoading={isLoading} />

        {hasNextPage && !isLoading &&
          <GridItem xs={12}>
            <FlexCol sx={{ alignItems: 'center' }}>
              <ShowMoreButton variant='contained' onClick={onClickShowMore}>
                {t('show-more-ai-tools')}
              </ShowMoreButton>
            </FlexCol>
          </GridItem>
        }
      </GridContainer>
    </PageContainer>
  );
};

export default RecommendedToolsPage;
