import React from 'react';
import { Helmet } from 'react-helmet';

import { getToolPageLink } from '../../../libs/router/config/routes';

const ToolPageMeta = ({
  toolName,
  categoryName,
  toolImage,
  toolId,
  categoryId
}: {
  toolName: string;
  categoryName: string;
  toolImage: string;
  toolId: string;
  categoryId: string;
}) => {
  return (
    <Helmet>
      <title>{`${toolName} - AI Tool for ${categoryName} - Reviews and Alternatives`}</title>
      <meta
        property='title'
        name='title'
        content={`${toolName} - AI Tool for ${categoryName} - Reviews and Alternatives`}
      />
      <meta
        property='description'
        name='description'
        content={
          `Explore all ${toolName}'s product features ` +
          'you need to know and join our community of AI and technology enthusiasts'
        }
      />
      <meta
        property='og:title'
        name='title'
        content={`${toolName} - AI Tool for ${categoryName} - Reviews and Alternatives`}
      />
      <meta
        property='og:description'
        name='description'
        content={
          `Explore all ${toolName}'s product features ` +
          'you need to know and join our community of AI and technology enthusiasts'
        }
      />
      <meta property='og:url' content={getToolPageLink(categoryId, toolId)}/>
      <meta property='og:image' content={toolImage}/>
      <link rel='canonical' href={getToolPageLink(categoryId, toolId, true)} />
    </Helmet>
  );
};

export default ToolPageMeta;
