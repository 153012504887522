import { Grid } from '@mui/material';
import React from 'react';

import ToolReview from './ToolReview';
import GridItem from '../../../components/layout/GridItem';
import { IToolReview } from '../../../libs/api/hooks/useGetToolReviews';

interface ToolReviewListProps {
  reviews: IToolReview[];
  toolId: string;
}

const ToolReviewsList = ({ reviews, toolId }: ToolReviewListProps) => {
  if (!reviews?.length) {
    return null;
  }

  return (
    <Grid container columns={12} spacing={4}>
      {reviews.map((review) =>
        <GridItem key={review.id} xs={12}>
          <ToolReview review={review} toolId={toolId}/>
        </GridItem>
      )}
    </Grid>
  );
};

export default ToolReviewsList;
