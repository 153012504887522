import {
  List,
  ListItem,
  Box,
  BoxProps,
  styled,
  Typography,
  useTheme,
  Button,
  useMediaQuery
} from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '../../assets/icons';
import { ICategory } from '../../libs/api/hooks/useGetCategoryById';
import { getCategoriesPageLink, getCategoryPageLink } from '../../libs/router/config/routes';
import { FlexRow } from '../layout/FlexRow';
import { LinkBox } from '../styled/LinkBox';

interface HorizontalProductsListProps {
  categories: ICategory[];
  sx?: BoxProps['sx'];
}

const HorizontalCategoriesList = ({ categories, sx = {} }: HorizontalProductsListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const listRef = useRef<HTMLUListElement>(null);

  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <FlexRow sx={{ alignItems: 'center', ...sx }}>
      <Box
        sx={{
          overflow: 'scroll',
          width: '100%',
          flex: 1,
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <List
          ref={listRef}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            margin: 0
          }}
        >
          {categories.map((category) =>
            <ListItem
              key={category.id}
              dense
              disablePadding
              onMouseOver={(e) => {
                e.currentTarget.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'nearest'
                });
              }}
            >
              <LinkBox to={getCategoryPageLink(category.id)}>
                <CategoryContainer>
                  <Typography
                    paddingX={4}
                    paddingY={2}
                    fontSize={14}
                    textAlign='center'
                    whiteSpace='nowrap'
                    sx={{
                      color: theme.palette.customColor.gray.dark,
                      fontWeight: theme.typography.fontWeightLight,
                      '&:hover': {
                        color: '#000000',
                        fontWeight: theme.typography.fontWeightBold,
                        backgroundColor: '#F2F2F2'
                      }
                    }}
                  >
                    {category.category}
                  </Typography>
                </CategoryContainer>
              </LinkBox>
            </ListItem>
          )}
        </List>
      </Box>

      {!isSmallScreen &&
        <Button
          href={getCategoriesPageLink()}
          sx={{
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontSize: 14,
            letterSpacing: -0.28,
            fontWeight: theme.typography.fontWeightBold,
            paddingX: 4,
            paddingY: 2,
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#F2F2F2'
            },
            marginLeft: 2
          }}
          variant='text'
          endIcon={
            <ArrowRightIcon style={{ width: 22, height: 22, color: theme.palette.primary.main }} />
          }
        >
          {t('view-all-categories')}
        </Button>
      }
    </FlexRow>
  );
};

export default HorizontalCategoriesList;

const CategoryContainer = styled(Box)(() => ({
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  overflow: 'hidden',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  justifyContent: 'center'
}));
